import './switch.css';

import React, { useState } from 'react';

export interface SwitchOptions {
    label: string;
    value: string;
}

interface SwitchProps {
    className?: string;
    options: SwitchOptions[];
    value: string;
    onChange: (value: string) => void;
}

const Switch: React.FC<SwitchProps> = ({ className, options, value, onChange }) => {
    return (
        <div className={`switch ${className}`}>
            {options.map((option, index) => (
                <button
                    key={index}
                    className={`switch-option ${option.value === value ? 'active' : ''}`}
                    disabled={option.value === value}
                    onClick={() => {
                        onChange(option.value);
                    }}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
};

export default Switch;
