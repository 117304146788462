import './construction-update-card.css';

import React, { useState } from 'react';
import { SPACES_ENDPOINT } from '../../utils/constants';
import TheIvyImage from "../../assets/images/the-ivy.jpeg";
import { formatDateTimeToDate } from '../../utils/helpers';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface ConstructionUpdateCardProps {
    imageSrc?: string;
    title: string;
    description: string;
    created_at: string;
    onClick: () => void;
}

const ConstructionUpdateCard: React.FC<ConstructionUpdateCardProps> = ({
    imageSrc,
    title,
    description,
    created_at,
    onClick,
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <div className={`construction-update-card h-100 ${imageLoaded ? 'start-fade-animation' : ''}`} onClick={onClick}>
            <LazyLoadImage
                src={imageSrc ? `${SPACES_ENDPOINT}${imageSrc}` : TheIvyImage}
                alt={title}
                className="construction-update-image"
                effect="opacity"
                width={"100%"}
                onLoad={() => setImageLoaded(true)}
            />
            <div className="construction-update-content">
                <h3 className="construction-update-title">{title}</h3>
                <p className="construction-update-description">{description}</p>
                <p className="construction-update-date">Veröffentlicht am: {formatDateTimeToDate(created_at)}</p>
            </div>
        </div>
    );
};

export default ConstructionUpdateCard;
