import { API_URL } from "../utils/constants";

export const downloadDocument = async (
  documentId: number
): Promise<Blob | null> => {
  const response = await fetch(`${API_URL}/documents/${documentId}/download`, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  const fileBlobResponse = await response.blob();
  return fileBlobResponse;
};

export const deleteDocument = async (document_id: number): Promise<boolean> => {
  const response = await fetch(`${API_URL}/documents/${document_id}`, {
    method: "DELETE",
    credentials: "include",
  });

  return response.status === 200;
};
