import './course-module-item-card.css';

import React, { useState } from 'react';
import { SPACES_ENDPOINT } from '../../utils/constants';
import { formatDateTimeToDate } from '../../utils/helpers';
import { IoPlay } from 'react-icons/io5';
import DOMPurify from 'dompurify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VideoPlaceholderThumbnail from '../../assets/images/video-placeholder-thumbnail.webp';

interface CourseModuleItemCardProps {
    title: string;
    description: string;
    thumbnail: string;
    created_at: string;
    onClick: () => void;
}

const CourseModuleItemCard: React.FC<CourseModuleItemCardProps> = ({
    title,
    description,
    thumbnail,
    created_at,
    onClick,
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <div className={`course-module-item-card h-100 ${imageLoaded ? 'start-fade-animation' : ''}`} onClick={onClick}>
            <div className="course-module-item-image-wrapper">
            <LazyLoadImage
                    src={`${SPACES_ENDPOINT}${thumbnail}`}
                    alt={title}
                    className="course-module-item-image"
                    effect="blur"
                    width={"100%"}
                    placeholderSrc={VideoPlaceholderThumbnail}
                    onLoad={() => setImageLoaded(true)}
                />
                <IoPlay className="course-module-item-play-icon" />
            </div>
            <div className="course-module-item-content">
                <h3 className="course-module-item-title">{title}</h3>
                <div className="course-module-item-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
                <p className="course-module-item-date">Veröffentlicht am: {formatDateTimeToDate(created_at)}</p>
            </div>
        </div>
    );
};

export default CourseModuleItemCard;
