import { API_URL } from "../utils/constants";
import { IAddress } from "./addressService";

export interface IStatus {
  status_id: number;
  status_name: string;
}

export interface ISalesAgent {
  employee_id: number;
  first_name: string;
  last_name: string;
  avatar: string | null;
}

export interface IDocument {
  document_id: number;
  document_type?: string | null;
  file_type: string;
  file_path: string;
  file_name: string;
  valid_until: string | null;
  created_at: string;
  updated_at: string;
}

export interface IBankDetail {
  details_id: number;
  iban: string;
  swift: string;
  bank_name: string;
  account_holder_name: string;
  currency: string;
  branch_number?: string | null;
  account_number?: number | null;
  created_at: string;
  updated_at: string;
}

export interface IInvestor {
  investor_id: number;
  first_name: string;
  last_name: string;
  email: string;
  status: IStatus;
  sales_agent: ISalesAgent | null;
  avatar?: string | null;
  phone_number?: string | null;
  created_at: string;
  updated_at: string;
  address: IAddress;
  passport: IDocument[];
  proofOfAddress: IDocument[];
  documents: IDocument[];
  bank_details: IBankDetail[];
}

export const fetchInvestors = async (
  statusId: number | null,
  search: string,
  page?: number,
  pageSize?: number
): Promise<IInvestor[]> => {
  let queryParams = new URLSearchParams();

  if (search.trim().length > 0) {
    queryParams.append("search", encodeURIComponent(search));
  }
  if (statusId !== null) {
    queryParams.append("statusId", statusId.toString());
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }

  let url = `${API_URL}/investors?${queryParams.toString()}`;

  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.investors;
};

export const fetchInvestor = async (): Promise<IInvestor> => {
  const response = await fetch(`${API_URL}/investors`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

export const createInvestor = async (
  first_name: string,
  last_name: string,
  email: string,
  phone_number: string,
  sales_agent_id: number,
  status_id: number
): Promise<IInvestor> => {
  const response = await fetch(`${API_URL}/investors`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name,
      last_name,
      email,
      phone_number,
      sales_agent_id,
      status_id,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const updateInvestor = async (
  email: string,
  phone_number: string
): Promise<void> => {
  const response = await fetch(`${API_URL}/investors`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      phone_number,
    }),
  });

  if (response.status === 200) return Promise.resolve();
  const data = await response.json();
  throw new Error(data.message);
};

export const uploadInvestorAvatar = async (
  avatarFile: File
): Promise<string> => {
  const formData = new FormData();
  formData.append("avatar", avatarFile);

  const response = await fetch(`${API_URL}/investors/avatar`, {
    method: "PATCH",
    credentials: "include",
    body: formData,
  });

  const data = await response.json();
  if (response.status === 200) return data.avatar;
  else throw new Error(data.message);
};

export const uploadInvestorPassport = async (
  files: File[]
): Promise<IDocument[]> => {
  const formData = new FormData();

  // Append the file file to the form data if provided
  files.forEach((file, index) => {
    formData.append(`file_${index}`, file);
  });

  const response = await fetch(`${API_URL}/investors/passport`, {
    method: "POST",
    credentials: "include",
    body: formData,
  });

  const data = await response.json();
  if (response.status === 201) return data.documents;
  else throw new Error(data.message);
};

export const uploadInvestorProofOfAddress = async (
  files: File[]
): Promise<IDocument[]> => {
  const formData = new FormData();

  // Append the file file to the form data if provided
  files.forEach((file, index) => {
    formData.append(`file_${index}`, file);
  });

  const response = await fetch(`${API_URL}/investors/proof-of-address`, {
    method: "POST",
    credentials: "include",
    body: formData,
  });

  const data = await response.json();
  if (response.status === 201) return data.documents;
  else throw new Error(data.message);
};
