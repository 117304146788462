import './topbar.css';

import React, { useCallback, useEffect, useState } from 'react';
import { BsInfoCircle, BsList, BsSearch, BsX } from "react-icons/bs";
import UserDefaultAvatar from '../../assets/images/default-avatar.webp';
import { useLocation, useNavigate } from 'react-router-dom';
import useSearchStore from '../../stores/searchStore';
import InfoModal from '../../modals/info-modal/info-modal';
import useInvestorDetailStore from '../../stores/investorDetailStore';
import { SPACES_ENDPOINT } from '../../utils/constants';

interface TopbarProps {
    isSidebarVisible: boolean;
    toggleSidebar: () => void;
}

const Topbar: React.FC<TopbarProps> = ({ isSidebarVisible, toggleSidebar }) => {
    const navigate = useNavigate();
    const location = useLocation();

    // Stores
    const { investor } = useInvestorDetailStore();
    const { search, setSearch } = useSearchStore();

    // States
    const [title, setTitle] = useState<string>("");
    const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);
    const [isInfoModalVisible, setIsInfoModalVisible] = useState<boolean>(false);

    // Set Title
    useEffect(() => {
        setIsSearchVisible(false);
        if (location.pathname === "/") {
            setTitle("Mein Profil");
        }
        else if (location.pathname === "/course") {
            setTitle("After Sale Kurs");
            //setIsSearchVisible(true);
        }
        else if (location.pathname === "/construction-updates") {
            setTitle("Baufortschritte");
        }
        else if (location.pathname === "/faq") {
            setTitle("Häufig gestellte Fragen");
        }
        else if (location.pathname === "/my-units") {
            setTitle("Meine Immobilien");
        }
        else if (location.pathname.includes("/my-units/")) {
            setTitle("Details der Immobilie");
        }
    }, [location]);

    // Functions
    const handleSearchInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }, []);

    return (
        <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container-fluid d-flex justify-content-between">
                <div>
                    <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
                        {isSidebarVisible ? <BsX color="black" size={28} /> : <BsList color="black" size={28} />}
                    </button>
                    <span className="ms-2 navbar-title">{title}</span>
                </div>

                <div className="d-flex align-items-center">
                    {/* Large screens */}
                    {isSearchVisible && (
                        <div className="d-none d-lg-flex align-items-center">
                            <div className="search-container me-3">
                                <BsSearch className="search-icon" />
                                <input
                                    className="search-input form-control"
                                    type="search"
                                    placeholder="Suche nach..."
                                    aria-label="Search"
                                    value={search}
                                    onChange={handleSearchInputChange}
                                />
                            </div>
                        </div>
                    )}
                    <BsInfoCircle
                        className="notification-icon me-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setIsInfoModalVisible(true)}
                    />
                    <span className="d-none d-lg-flex">{investor?.first_name} {investor?.last_name}</span>
                    <img
                        className="avatar ms-3 me-3"
                        src={investor?.avatar && investor.avatar.length > 0 ? `${SPACES_ENDPOINT}${investor.avatar}` : UserDefaultAvatar}
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate("/")}
                    />
                </div>
            </div>
            {isInfoModalVisible && (
                <InfoModal onClose={() => setIsInfoModalVisible(false)} />
            )}
        </nav>
    );
};

export default Topbar;
