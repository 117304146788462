// UnitDetails.tsx
import React, { useState } from 'react';
import { IUnit } from '../../../../services/unitService';
import { SPACES_ENDPOINT } from '../../../../utils/constants';
import PictureModal from '../../../../modals/show-picture-modal/show-picture-modal';
import { FaBed, FaBath, FaRuler, FaBuilding } from 'react-icons/fa';
import styles from './unit-details.module.css';

interface UnitDetailsProps {
    unit: IUnit;
}

const UnitDetails: React.FC<UnitDetailsProps> = ({ unit }) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    return (
        <>
            <div className={styles.unitDetailsCard}>
                <div className={styles.imageContainer}>
                    {unit.pictures.length > 1 ? (
                        <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                {unit.pictures.map((picture, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        data-bs-target="#carouselExampleCaptions"
                                        data-bs-slide-to={index}
                                        className={index === 0 ? "active" : ""}
                                        aria-current={index === 0 ? "true" : "false"}
                                        aria-label={`Slide ${index + 1}`}
                                    ></button>
                                ))}
                            </div>
                            <div className="carousel-inner">
                                {unit.pictures.map((picture, index) => (
                                    <div
                                        key={index}
                                        className={`carousel-item ${index === 0 ? "active" : ""}`}
                                        data-bs-interval="5000"
                                    >
                                        <img
                                            src={`${SPACES_ENDPOINT}${picture.picture_path}`}
                                            className={`d-block w-100 ${styles.unitImage}`}
                                            role='button'
                                            alt={`Unit ${index + 1}`}
                                            onClick={() => setSelectedImage(`${SPACES_ENDPOINT}${picture.picture_path}`)}
                                        />
                                    </div>
                                ))}
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                <span className={`carousel-control-prev-icon ${styles.carouselControlPrevIcon}`} aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                <span className={`carousel-control-next-icon ${styles.carouselControlNextIcon}`} aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    ) : (
                        <img
                            src={unit.pictures.length > 0 ? `${SPACES_ENDPOINT}${unit.pictures[0].picture_path}` : "https://placehold.co/600x400"}
                            className={`d-block w-100 ${styles.unitImage}`}
                            alt="Unit 1"
                        />
                    )}
                </div>
                <div className={styles.unitInfo}>
                    <h5 className={styles.unitName}>{unit.unit_number}</h5>
                    <p className={styles.propertyName}>{unit.property.property_name}</p>
                    <div className={styles.divider} />
                    <div className="row gy-4 py-3">
                        <div className="col-6 col-xl-4">
                            <FaBuilding className={styles.icon} />
                            <span className={styles.infoText}>Etage {unit.floor}</span>
                        </div>
                        <div className="col-6 col-xl-4">
                            <FaRuler className={styles.icon} />
                            <span className={styles.infoText}>{unit.square_feet} sqft</span>
                        </div>
                        <div className="col-6 col-xl-4">
                            <FaBed className={styles.icon} />
                            <span className={styles.infoText}>{unit.bedroom} Schlafzimmer</span>
                        </div>
                        <div className="col-6 col-xl-4">
                            <FaBath className={styles.icon} />
                            <span className={styles.infoText}>{unit.bathroom} Badezimmer</span>
                        </div>
                        {/*unit.owner && (
                            <>
                                <div className={styles.statItem}>
                                    <FaCalendarAlt className={styles.icon} />
                                    <span>{new Date(unit.created_at).toLocaleDateString()}</span>
                                </div>
                                <div className={styles.statItem}>
                                    <FaMoneyBillWave className={styles.icon} />
                                    <span>{unit.owner?.purchase_price.toLocaleString('en-US', { style: 'currency', currency: 'AED' })}</span>
                                </div>
                            </>
                        )*/}
                    </div>
                </div>
            </div>

            {selectedImage && (
                <PictureModal
                    image={selectedImage}
                    onClose={() => setSelectedImage(null)}
                    onNext={() => {
                        if (unit.pictures) {
                            const totalImages = unit.pictures.length;
                            const currentIndex = unit.pictures.findIndex(picture => `${SPACES_ENDPOINT}${picture.picture_path}` === selectedImage);
                            const nextIndex = (currentIndex + 1) % totalImages;
                            setSelectedImage(`${SPACES_ENDPOINT}${unit.pictures[nextIndex].picture_path}`);
                        }
                    }}
                    onPrevious={() => {
                        if (unit.pictures) {
                            const totalImages = unit.pictures.length;
                            const currentIndex = unit.pictures.findIndex(picture => `${SPACES_ENDPOINT}${picture.picture_path}` === selectedImage);
                            const prevIndex = (currentIndex - 1 + totalImages) % totalImages;
                            setSelectedImage(`${SPACES_ENDPOINT}${unit.pictures[prevIndex].picture_path}`);
                        }
                    }}
                />
            )}
        </>
    );
};

export default UnitDetails;
