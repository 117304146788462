import { useState, useEffect } from 'react';

const useNumberAnimation = (target: number, duration: number = 1000): number => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        let start = performance.now();

        const step = (timestamp: number) => {
            const progress = Math.min((timestamp - start) / duration, 1);
            const currentValue = Math.floor(progress * target);
            setValue(currentValue);
            if (progress < 1) {
                requestAnimationFrame(step);
            }
        };

        requestAnimationFrame(step);

        return () => setValue(0);
    }, [target, duration]);

    return value;
};

export default useNumberAnimation;
