import './investor-bank-details-section.css';

import React, { useEffect, useState } from 'react';
import CustomInputField from '../../../../components/custom-input-field/custom-input-field';
import currencyCodes from 'currency-codes';
import { IBankDetail } from '../../../../services/investorService';
import CustomSelectField, { CustomSelectFieldOptions } from '../../../../components/custom-select-field/custom-select-field';

export interface BankDetailsForm {
    iban: string;
    swift: string;
    bank_name: string;
    account_holder_name: string;
    currency: string;
    branch_number?: string | null;
    account_number?: number | null;
}

interface Errors {
    iban: string;
    swift: string;
    bank_name: string;
    account_holder_name: string;
    [key: string]: string;
}

interface InvestorBankDetailsSectionProps {
    bank_details: IBankDetail | null;
    disabled?: boolean;
}

const InvestorBankDetailsSection: React.FC<InvestorBankDetailsSectionProps> = ({ bank_details, disabled = true }) => {
    // States
    const [currencyOptions, setCurrencyOptions] = useState<CustomSelectFieldOptions[]>([]);
    const [bankDetailsForm, setBankDetailsForm] = useState<BankDetailsForm>({
        iban: '',
        swift: '',
        bank_name: '',
        account_holder_name: '',
        currency: 'EUR',
        branch_number: null,
        account_number: null,
    });
    const [errors, setErrors] = useState<Errors>({
        iban: '',
        swift: '',
        bank_name: '',
        account_holder_name: '',
    });

    useEffect(() => {
        const currencies = currencyCodes.data.map(({ code, currency }) => ({
            value: code,
            label: `${currency} (${code})`
        }));
        setCurrencyOptions(currencies);
    }, []);

    useEffect(() => {
        if (bank_details) {
            setBankDetailsForm({
                iban: bank_details.iban,
                swift: bank_details.swift,
                bank_name: bank_details.bank_name,
                account_holder_name: bank_details.account_holder_name,
                currency: bank_details.currency,
                account_number: bank_details.account_number,
            });
        } else {
            setBankDetailsForm({
                iban: '',
                swift: '',
                bank_name: '',
                account_holder_name: '',
                currency: 'EUR',
                branch_number: null,
                account_number: null,
            });
        }
    }, [bank_details]);

    // Functions
    const handleChange = (name: keyof BankDetailsForm) => (value: string) => {
        const error = !value && ['iban', 'swift', 'bank_name', 'account_holder_name'].includes(name) ? 'This field is required' : '';
        setBankDetailsForm(prevDetails => ({
            ...prevDetails,
            [name]: name === 'account_number' ? parseInt(value, 10) : value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }));
    };

    return (
        <div>
            <h4>Bank Informationen</h4>
            <div className="white-card">
                <div className="row">
                    <div className="col-12 col-md-6 col-xxl-4">
                        <CustomInputField
                            id="iban"
                            label="IBAN"
                            value={bankDetailsForm.iban}
                            onChange={handleChange('iban')}
                            onClear={() => handleChange('iban')("")}
                            errorMessage={errors.iban}
                            placeholder={disabled ? "-" : "DE89 3704 0044 0532 0130 00"}
                            required
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <CustomInputField
                            id="swift"
                            label="SWIFT-Code"
                            value={bankDetailsForm.swift}
                            onChange={handleChange('swift')}
                            onClear={() => handleChange('swift')("")}
                            errorMessage={errors.swift}
                            placeholder={disabled ? "-" : "DEUTDEDBFRA"}
                            required
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <CustomInputField
                            id="bank_name"
                            label="Bankname"
                            value={bankDetailsForm.bank_name}
                            onChange={handleChange('bank_name')}
                            onClear={() => handleChange('bank_name')("")}
                            errorMessage={errors.bank_name}
                            placeholder={disabled ? "-" : "Deutsche Bank"}
                            required
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <CustomInputField
                            id="account_holder_name"
                            label="Kontoinhaber"
                            value={bankDetailsForm.account_holder_name}
                            onChange={handleChange('account_holder_name')}
                            onClear={() => handleChange('account_holder_name')("")}
                            errorMessage={errors.account_holder_name}
                            placeholder={disabled ? "-" : "Max Mustermann"}
                            required
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <CustomInputField
                            id="branch_number"
                            label="Filialnummer"
                            value={bankDetailsForm.branch_number || ''}
                            onChange={handleChange('branch_number')}
                            onClear={() => handleChange('branch_number')("")}
                            placeholder={disabled ? "-" : "123456"}
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <CustomInputField
                            id="account_number"
                            label="Kontonummer"
                            type="number"
                            value={bankDetailsForm.account_number?.toString() || ''}
                            onChange={handleChange('account_number')}
                            onClear={() => handleChange('account_number')("")}
                            placeholder={disabled ? "-" : "0123456789"}
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xxl-4">
                        <CustomSelectField
                            id="investor-bank-details-currency-selector"
                            label="Währung"
                            value={bankDetailsForm.currency}
                            onChange={handleChange('currency')}
                            options={currencyOptions}
                            errorMessage=""
                            placeholder={disabled ? "-" : "EUR"}
                            required
                            labelOnTop
                            showClearIcon
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvestorBankDetailsSection;
