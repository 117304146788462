import "./course-detail-page.css";

import DOMPurify from 'dompurify';
import useCourseStore from "../../stores/courseStore";
import { useCallback, useState } from "react";
import { API_URL, SPACES_ENDPOINT } from "../../utils/constants";
import { IoPlay } from "react-icons/io5";

const CourseDetailPage = () => {
    // Stores
    const selectedModuleItem = useCourseStore(state => state.selectedModuleItem);

    // States
    const [videoUrl, setVideoUrl] = useState<string | null>(null);

    const onClickPlayVideo = useCallback(() => {
        if (selectedModuleItem) {
            setVideoUrl(`${API_URL}/course-modules/${selectedModuleItem.module_id}/items/${selectedModuleItem.item_id}/video`);
        }
    }, [selectedModuleItem]);

    return (
        <>
            {selectedModuleItem && (
                <>
                    <h4 className="mb-4">{selectedModuleItem.title}</h4>

                    {/* VIDEO */}
                    <div className="white-card course-detail-video-wrapper">
                        {videoUrl ? (
                            <div className="course-detail-video-responsive">
                                <video
                                    width="560"
                                    height="315"
                                    controls
                                    controlsList="nodownload">
                                    <source src={videoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        ) : (
                            <div className="course-detail-video-thumbnail-wrapper" onClick={onClickPlayVideo}>
                                <img className="course-detail-video-thumbnail" src={`${SPACES_ENDPOINT}${selectedModuleItem.thumbnail_path}`} alt="Thumbnail" />
                                <IoPlay className="course-detail-play-icon" />
                            </div>
                        )}
                    </div>

                    {/* DESCRIPTION */}
                    <h4 className="mt-5">Beschreibung</h4>
                    <div className="white-card mt-3">
                        <div className="course-detail-description-html" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedModuleItem.description) }} />
                    </div>
                </>
            )}
        </>
    );
};

export default CourseDetailPage;
