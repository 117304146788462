import { API_URL } from "../utils/constants";

export interface ICourseModule {
  module_id: number;
  title: string;
  description: string;
  created_at: string;
}

export interface ICourseModuleItem {
  item_id: number;
  module_id: number;
  title: string;
  description: string;
  video_path: string;
  thumbnail_path: string;
  created_at: string;
  updated_at: string;
}

export const fetchCourseModules = async (): Promise<ICourseModule[]> => {
  const response = await fetch(`${API_URL}/course-modules`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.modules;
};

export const fetchCourseModuleItems = async (
  module_id: number,
  page?: number,
  pageSize?: number
): Promise<ICourseModuleItem[]> => {
  let queryParams = new URLSearchParams();
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }

  let url = `${API_URL}/course-modules/${module_id}/items?${queryParams.toString()}`;

  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.items;
};

export const downloadCourseVideo = async (
  module_id: number,
  item_id: number
): Promise<Blob | null> => {
  const response = await fetch(`${API_URL}/course-modules/${module_id}/items/${item_id}/video`, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  const fileBlobResponse = await response.blob();
  return fileBlobResponse;
};
