import "./construction-update-page.css";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IConstructionUpdate, fetchConstructionUpdate } from "../../services/constructionUpdateService";
import useAlertStore from "../../stores/alertStore";
import { SPACES_ENDPOINT } from "../../utils/constants";
import DOMPurify from 'dompurify';
import PictureModal from "../../modals/show-picture-modal/show-picture-modal";
import { getYouTubeId, isYouTubeUrl } from "../../utils/helpers";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading-anim.json";

const ConstructionUpdatePage = () => {
    let { updateId } = useParams();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [constructionUpdate, setConstructionUpdate] = useState<IConstructionUpdate | null>(null);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    useEffect(() => {
        if (updateId) {
            const fetchUpdate = async () => {
                try {
                    setIsLoading(true);
                    const update = await fetchConstructionUpdate(Number(updateId));
                    setConstructionUpdate(update);
                } catch (error) {
                    console.log("Error while fetching construction update:", error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsLoading(false);
                }
            }
            fetchUpdate();
        }
    }, [updateId]);

    return (
        <>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                constructionUpdate && (
                    <>
                        <h4 className="mb-4">{constructionUpdate.title}</h4>
                        <div className="white-card">
                            <div className="construction-update-description-html" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(constructionUpdate.description) }} />
                        </div>

                        {/* VIDEO */}
                        {constructionUpdate.video_url && (
                            <>
                                <h4 className="mt-5">Video</h4>
                                <div className="white-card video-wrapper">
                                    <div className="video-responsive">
                                        <iframe
                                            width="560"
                                            height="315"
                                            src={isYouTubeUrl(constructionUpdate.video_url) ?
                                                `https://www.youtube.com/embed/${getYouTubeId(constructionUpdate.video_url)}` :
                                                constructionUpdate.video_url}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        />
                                    </div>
                                </div>
                            </>
                        )}

                        {/* IMAGES */}
                        {constructionUpdate.pictures && constructionUpdate.pictures.length > 0 && (
                            <>
                                <h4 className="mt-5">Fotos</h4>
                                <div className="white-card">
                                    {constructionUpdate.pictures?.length === 0 ? (
                                        <p>Keine hochgeladen</p>
                                    ) : (
                                        <div className="row gx-2 gy-2">
                                            {constructionUpdate.pictures?.map((image, index) => (
                                                <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-2">
                                                    <div className="image-square" onClick={() => setSelectedImage(image.picture_path)}>
                                                        <img src={`${SPACES_ENDPOINT}${image.picture_path}`} alt={`image-${index}`} className="img-fluid" />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {selectedImage && (
                                    <PictureModal
                                        image={selectedImage}
                                        onClose={() => setSelectedImage(null)}
                                        onNext={() => {
                                            if (constructionUpdate.pictures) {
                                                const totalImages = constructionUpdate.pictures.length;
                                                const currentIndex = constructionUpdate.pictures.findIndex(picture => `${SPACES_ENDPOINT}${picture.picture_path}` === selectedImage);
                                                const nextIndex = (currentIndex + 1) % totalImages; // Wraps around to the first image after the last image
                                                setSelectedImage(`${SPACES_ENDPOINT}${constructionUpdate.pictures[nextIndex].picture_path}`);
                                            }
                                        }}
                                        onPrevious={() => {
                                            if (constructionUpdate.pictures) {
                                                const totalImages = constructionUpdate.pictures.length;
                                                const currentIndex = constructionUpdate.pictures.findIndex(picture => `${SPACES_ENDPOINT}${picture.picture_path}` === selectedImage);
                                                const prevIndex = (currentIndex - 1 + totalImages) % totalImages; // Wraps around to the last image before the first image
                                                setSelectedImage(`${SPACES_ENDPOINT}${constructionUpdate.pictures[prevIndex].picture_path}`);
                                            }
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </>
                )
            )}
        </>
    );
};

export default ConstructionUpdatePage;
