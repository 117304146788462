// ConstructionUpdatesPage.tsx

import { useCallback, useEffect } from "react";
import Switch, { SwitchOptions } from "../components/switch/switch";
import ConstructionUpdateCard from "../components/construction-update-card/construction-update-card";
import { useNavigate } from "react-router-dom";
import useConstructionUpdateStore from "../stores/constructionUpdateStore";
import { fetchConstructionUpdates } from "../services/constructionUpdateService";
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "lottie-react";
import LoadingAnim from "../assets/anims/loading-anim.json";

const switchOptions: SwitchOptions[] = [
    { label: "The Vybe", value: "3" },
    { label: "Haven Gardens", value: "4" },
];

const ConstructionUpdatesPage = () => {
    const navigate = useNavigate();

    // Stores
    const {
        updates,
        currentPage,
        selectedPropertyId,
        isLoading,
        isInitialLoaded,
        moreDataAvailable,
        setUpdates,
        setCurrentPage,
        setSelectedPropertyId,
        setIsLoading,
        setIsInitialLoaded,
        setMoreDataAvailable
    } = useConstructionUpdateStore();

    const loadUpdates = useCallback(async () => {
        setIsLoading(true);
        try {
            const fetchedUpdates = await fetchConstructionUpdates(selectedPropertyId, currentPage, 20);
            setUpdates(currentPage === 1 ? fetchedUpdates : [
                ...updates,
                ...fetchedUpdates,
            ]);
            setMoreDataAvailable(fetchedUpdates.length === 20);
        } catch (error) {
            console.error('Error while fetching construction updates:', error);
        } finally {
            setIsLoading(false);
        }
    }, [selectedPropertyId, currentPage]);

    useEffect(() => {
        loadUpdates();
    }, [loadUpdates]);

    // Infinite scroll fetch function
    const fetchMoreData = () => {
        if (moreDataAvailable && !isLoading) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleFilterClick = (value: string) => {
        setIsInitialLoaded(false);
        setSelectedPropertyId(Number(value));
    }

    const onClickUpdate = (updateId: number) => {
        const switchOption = switchOptions.find(option => Number(option.value) === selectedPropertyId);
        if (switchOption) {
            navigate(`/construction-update/${updateId}?propertyName=${encodeURIComponent(switchOption.label)}&propertyId=${selectedPropertyId}`);
        }
    }

    return (
        <>
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-center align-items-center">
                <Switch
                    options={switchOptions}
                    value={selectedPropertyId.toString()}
                    onChange={handleFilterClick}
                />
            </div>
            <div className="d-flex justify-content-between align-items-center mt-5">
                <h4 className="my-0">({updates.length}) Updates</h4>
            </div>
            {isLoading && !isInitialLoaded ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                updates.length === 0 ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                        <p>Keine Baufortschritte vorhanden.</p>
                    </div>
                ) : (
                    <InfiniteScroll
                        style={{ overflowY: "hidden", overflowX: "hidden" }}
                        dataLength={updates.length}
                        next={fetchMoreData}
                        hasMore={moreDataAvailable}
                        loader={
                            <div className="d-flex justify-content-center mt-4">
                                <div className="spinner-border text-primary" role="status" />
                            </div>
                        }
                        scrollableTarget="content-area"
                    >
                        <div className="row mt-1 mb-2 gy-4">
                            {updates.map((update, index) => (
                                <div className="col-12 col-sm-6 col-xl-4" key={index}>
                                    <ConstructionUpdateCard
                                        imageSrc={update.picture_path}
                                        title={update.title}
                                        description={update.short_description}
                                        created_at={update.created_at}
                                        onClick={() => onClickUpdate(update.update_id)}
                                    />
                                </div>
                            ))}
                        </div>
                    </InfiniteScroll>
                )
            )}
        </>
    );
};

export default ConstructionUpdatesPage;
