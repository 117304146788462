import './login-page.css';

import { useEffect, useState } from "react";
import DGLogo from "../../assets/images/dg-logo.png";
import HavensGardenImage from "../../assets/images/haven-gardens.webp";
import TheVybeImage from "../../assets/images/the-vybe.webp";
import CustomInputField from '../../components/custom-input-field/custom-input-field';
import CustomButton from '../../components/custom-button/custom-button';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/authService';
import useInvestorDetailStore from '../../stores/investorDetailStore';

const LoginPage = () => {
    const navigate = useNavigate();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loginError, setLoginError] = useState('');

    // Stores
    const { investor, setInvestor } = useInvestorDetailStore();

    useEffect(() => {
        if (investor) {
            navigate("/");
        }
    }, [investor]);

    const handleLogin = async () => {
        let valid = true;
        setEmailError('');
        setPasswordError('');

        // Basic email validation
        if (!email) {
            setEmailError('E-Mail darf nicht leer sein.');
            valid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email)) {
            setEmailError('Bitte gib eine gültige E-Mail Adresse ein.');
            valid = false;
        }

        // Check if the password is not empty
        if (!password) {
            setPasswordError('Passwort darf nicht leer sein.');
            valid = false;
        }

        if (valid) {
            try {
                setIsLoading(true);
                const investorData = await login(email, password);
                setInvestor(investorData);
            } catch (error) {
                // @ts-ignore
                setLoginError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="page-container-full">
            <div className="row">
                <div className="col-md-6 d-none d-md-flex flex-column left-panel align-items-center justify-content-center">
                    <div style={{ marginBottom: 100, width: '100%' }}>
                        <img src={DGLogo} width={200} className="mx-auto d-block" alt="DG Logo" />
                        <h4 className="text-center mt-4">Investorenportal</h4>
                        <div className="w-100 d-flex justify-content-center mt-5">
                            <div className="image-container" style={{ position: 'relative' }}>
                                <img className="havens-garden" src={HavensGardenImage} />
                                <img className="the-vybe" src={TheVybeImage} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center right-panel">
                    <img src={DGLogo} width={200} className="mx-auto d-md-none" alt="DG Logo" />
                    <h4 className="text-center mt-4 mb-5 d-md-none">Investorenportal</h4>
                    <h4 className="mb-5 d-none d-md-block">Willkommen</h4>
                    <form onSubmit={handleLogin} className="login-form">
                        <div className="mb-3">
                            <CustomInputField
                                id="email"
                                label="E-Mail"
                                type="email"
                                placeholder="max@mustermann.de"
                                value={email}
                                errorMessage={emailError}
                                onClear={() => setEmail("")}
                                onChange={(value: string) => {
                                    setEmail(value);
                                    setEmailError("");
                                }}
                                required
                            />
                        </div>
                        <div>
                            <CustomInputField
                                id="password"
                                label="Passwort"
                                type="password"
                                placeholder="********"
                                value={password}
                                errorMessage={passwordError}
                                onClear={() => setPassword("")}
                                onChange={(value: string) => {
                                    setPassword(value);
                                    setPasswordError("");
                                }}
                                required
                            />
                        </div>
                        {loginError && (
                            <p className="ms-2 mb-4 text-danger">{loginError}</p>
                        )}
                        <p className="mb-4 forgot-password" onClick={() => navigate("/reset-password")}>Passwort vergessen?</p>
                        <CustomButton
                            className="w-100"
                            title="Anmelden"
                            isLoading={isLoading}
                            onClick={handleLogin}
                        />
                    </form>
                    <div className="footer-container">
                        <p className="footer-copyright m-0">
                            &copy; 2024 Daniel Garofoli. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
