import { API_URL } from "../utils/constants";
import { IInvestor } from "./investorService";

export interface IEmployee {
  employee_id: number;
  first_name: string;
  last_name: string;
  email: string;
  avatar: string | null;
  created_at: string;
  updated_at: string;
}

export const checkSession = async (): Promise<void> => {
  const response = await fetch(`${API_URL}/auth/`, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message);
  }

  return Promise.resolve();
};

export const login = async (
  email: string,
  password: string
): Promise<IInvestor> => {
  const response = await fetch(`${API_URL}/auth/login`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  throw new Error(data.message);
};

export const logout = async (): Promise<boolean> => {
  const response = await fetch(`${API_URL}/auth/logout`, {
    method: "POST",
    credentials: "include",
  });

  return response.status === 200;
};

export const forgotPassword = async (email: string): Promise<boolean> => {
  const response = await fetch(`${API_URL}/auth/forgot-password`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  });

  if (response.status !== 201) {
    const data = await response.json();
    throw new Error(data.message);
  }
  return true;
};

export const checkForgotPasswordCode = async (
  code: string
): Promise<boolean> => {
  const response = await fetch(`${API_URL}/auth/check-reset-password-code`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code,
    }),
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  return data.isValid;
};

export const resetPassword = async (
  code: string,
  password: string
): Promise<boolean> => {
  const response = await fetch(`${API_URL}/auth/reset-password`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code,
      password,
    }),
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message);
  }
  return true;
};
