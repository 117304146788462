import './scroll-up-button.css';

import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { IoArrowUpCircleOutline } from 'react-icons/io5';

interface ScrollUpButtonProps {
    className?: string;
    scrollableRef: RefObject<HTMLDivElement>;
}

const ScrollUpButton: React.FC<ScrollUpButtonProps> = ({ className, scrollableRef }) => {
    // States
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const scrollContainer = scrollableRef.current;
        if (scrollContainer) {
            const handleScroll = () => {
                const scrollTop = scrollContainer.scrollTop;
                if (scrollTop > 300) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            };

            scrollContainer.addEventListener('scroll', handleScroll);

            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [scrollableRef]);

    const scrollToTop = useCallback(() => {
        scrollableRef?.current?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [scrollableRef]);

    return (
        <div className={`scroll-to-top-button ${isVisible ? 'visible' : ''} ${className ?? ""}`} onClick={scrollToTop}>
            <IoArrowUpCircleOutline />
        </div>
    );
};

export default ScrollUpButton;
