const isProduction = process.env.REACT_APP_ENV === "production";
const isTest = process.env.REACT_APP_ENV === "test";

export const API_URL = isProduction
  ? "https://investor-api.danielgarofoli.com"
  : isTest
  ? "https://investor-api-test.danielgarofoli.com"
  : "http://localhost:8585";
export const SPACES_ENDPOINT = isProduction
  ? "https://dg-home-prod.fra1.digitaloceanspaces.com/"
  : "https://dg-home-test.fra1.digitaloceanspaces.com/";
