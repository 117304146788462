import './unit-card.css';

import React, { useState } from 'react';
import { SPACES_ENDPOINT } from '../../utils/constants';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { formatDateTimeToDate, formatMoney } from '../../utils/helpers';
import { UnitPurposeType } from '../../services/unitService';

interface UnitCardProps {
    imageSrc?: string;
    propertyName: string;
    title: string;
    purchasePrice: number;
    rent: number;
    acquisitionDate: string;
    purpose_type: UnitPurposeType;
    onClick: () => void;
}

const UnitCard: React.FC<UnitCardProps> = ({
    imageSrc,
    propertyName,
    title,
    purchasePrice,
    rent,
    acquisitionDate,
    purpose_type,
    onClick,
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    const formattedPurposeType = purpose_type === "investment" ? "Investment" : "Selbstnutzung";

    return (
        <div className="unit-card" onClick={onClick}>
            <div className="image-container d-none d-md-block">
                <LazyLoadImage
                    src={imageSrc ? `${SPACES_ENDPOINT}${imageSrc}` : "https://placehold.co/600x400"}
                    alt={title}
                    className="unit-image"
                    effect="opacity"
                    onLoad={() => setImageLoaded(true)}
                />
            </div>
            <div className="unit-content">
                <div className="unit-header">
                    <div className="d-flex align-items-center">
                        <h3 className="unit-title">Immobilie #{title} -</h3>
                        <h4 className="unit-subtitle ms-2">{propertyName}</h4>
                    </div>
                    <div className={`unit-status ${rent > 0 ? 'rented' : ''}`}>
                        {rent > 0 ? 'Vermietet' : 'Mietersuche'}
                    </div>
                </div>
                <div className="unit-details mt-2">
                    <div className="unit-statistics">
                        <div className="unit-statistic">
                            <p className="unit-label">Investiert</p>
                            <p className="unit-number">{formatMoney(purchasePrice)} AED</p>
                        </div>
                        <div className="unit-statistic">
                            <p className="unit-label">Brutto Rendite (Jährlich)</p>
                            <p className="unit-number">{rent === 0 ? "-" : `${((rent / purchasePrice) * 100).toFixed(1)}%`}</p>
                        </div>
                        <div className="unit-statistic">
                            <p className="unit-label">Zweck der Immobilie</p>
                            <p className="unit-number">{formattedPurposeType}</p>
                        </div>
                        <div className="unit-statistic">
                            <p className="unit-label">Investiert am</p>
                            <p className="unit-number">{formatDateTimeToDate(acquisitionDate)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnitCard;
