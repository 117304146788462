import './payment-details.css';

import React from 'react';
import useNumberAnimation from '../../../../hooks/useNumberAnimation';

interface PaymentInfo {
    purchasePrice: number;
    annualRent: number;
}

interface PaymentDetailsProps {
    payments: PaymentInfo;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({ payments }) => {
    const animatedPurchasePrice = useNumberAnimation(payments.purchasePrice);
    const animatedAnnualRent = useNumberAnimation(payments.annualRent);
    const animatedROI = useNumberAnimation((payments.annualRent / payments.purchasePrice) * 100);

    return (
        <div className="payment-details">
            <div className="payment-details-card">
                <div className="row g-2 justify-content-center">
                    <div className="col-6 col-xl-4 number-item text-center mx-auto"><div className="p-2">{animatedPurchasePrice.toLocaleString()} AED <span>Investiert</span></div></div>
                    <div className="col-6 col-xl-4 number-item text-center mx-auto"><div className="p-2">{animatedAnnualRent.toLocaleString()} AED <span>Mieteinnahmen</span></div></div>
                    <div className="col-6 col-xl-4 number-item text-center mx-auto"><div className="p-2">{animatedROI.toFixed(1)}% <span>Bruttorendite (Jährlich)</span></div></div>
                </div>
            </div>
        </div>
    );
};

export default PaymentDetails;
