// MyUnitsPage.tsx
import "./my-units-page.css";

import { useCallback, useEffect } from "react";
//import Switch, { SwitchOptions } from "../../components/switch/switch";
import { fetchUnits } from "../../services/unitService";
import useUnitStore from "../../stores/unitStore";
import InfiniteScroll from "react-infinite-scroll-component";
import UnitCard from "../../components/unit-card/unit-card";
import { useNavigate } from "react-router-dom";
import { getActiveLease } from "../../utils/helpers";
import Lottie from "lottie-react";
import HouseAnim from "../../assets/anims/house-anim.json";
import LoadingAnim from "../../assets/anims/loading-anim.json";

/*const switchOptions: SwitchOptions[] = [
    { label: "The Ivy", value: "2" },
];*/

const MyUnitsPage = () => {
    const navigate = useNavigate();

    // States
    //const [selectedUnit, setSelectedUnit] = useState<string>("2");

    // Stores
    const {
        units,
        currentPage,
        isLoading,
        isInitialLoaded,
        moreDataAvailable,
        setUnits,
        setCurrentPage,
        setIsLoading,
        setIsInitialLoaded,
        setMoreDataAvailable
    } = useUnitStore();

    const loadUnits = useCallback(async () => {
        setIsLoading(true);
        try {
            const fetchedUnits = await fetchUnits(currentPage, 20);
            setUnits(currentPage === 1 ? fetchedUnits : [
                ...units,
                ...fetchedUnits,
            ]);
            setMoreDataAvailable(fetchedUnits.length === 20);
        } catch (error) {
            console.error('Error while fetching units:', error);
        } finally {
            setIsLoading(false);
        }
    }, [currentPage]);

    useEffect(() => {
        loadUnits();
    }, [loadUnits]);

    // Infinite scroll fetch function
    const fetchMoreData = () => {
        if (moreDataAvailable && !isLoading) {
            setCurrentPage(currentPage + 1);
        }
    };

    /*const handleFilterClick = (value: string) => {
        setSelectedUnit(value);
    }*/

    const onClickUnit = (unitId: number) => {
        navigate(`/my-units/${unitId}`);
    }

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            {/*<div className="d-flex justify-content-center align-items-center">
                <Switch
                    options={switchOptions}
                    value={selectedUnit}
                    onChange={handleFilterClick}
                />
    </div>*/}
            <div className="d-flex justify-content-between align-items-center">
                <h4 className="my-0">({units.length}) Immobilien</h4>
            </div>

            {isLoading && !isInitialLoaded ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                units.length === 0 ? (
                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Lottie className="mt-4" animationData={HouseAnim} loop={false} style={{ height: 400 }} />
                        <p className="no-units-available-message">Derzeit sind keine Immobilien in deinem Besitz aufgeführt.</p>
                    </div>
                ) : (
                    <InfiniteScroll
                        style={{ overflowY: "hidden", overflowX: "hidden", padding: 8 }}
                        dataLength={units.length}
                        next={fetchMoreData}
                        hasMore={moreDataAvailable}
                        loader={
                            <div className="d-flex justify-content-center mt-4">
                                <div className="spinner-border text-primary" role="status" />
                            </div>
                        }
                        scrollableTarget="content-area"
                    >
                        <div className="row mt-1 mb-2 gy-4">
                            {units.map((unit, index) => (
                                <div className="col-12 col-xxl-6" key={index}>
                                    <UnitCard
                                        imageSrc={unit.pictures.length > 0 ? unit.pictures[0].picture_path : ""}
                                        propertyName={unit.property.property_name}
                                        title={unit.unit_number}
                                        onClick={() => onClickUnit(unit.unit_id)}
                                        purchasePrice={unit.owner?.purchase_price ?? 0}
                                        rent={getActiveLease(unit.leases)?.annual_rent_amount ?? 0}
                                        acquisitionDate={unit.owner?.created_at ?? ""}
                                        purpose_type={unit.purpose_type}
                                    />
                                </div>
                            ))}
                        </div>
                    </InfiniteScroll>
                )
            )}
        </div>
    );
};

export default MyUnitsPage;
