// InvestorDetailPage.tsx

import "./investor-detail-page.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import _ from 'lodash';
import useInvestorDetailStore from "../../stores/investorDetailStore";
import { IDocument, IInvestor, fetchInvestor, updateInvestor, uploadInvestorAvatar, uploadInvestorPassport, uploadInvestorProofOfAddress } from "../../services/investorService";
import useAlertStore from "../../stores/alertStore";
import CustomInputField from "../../components/custom-input-field/custom-input-field";
import FileCard from "../../components/file-card/file-card";
import FileUpload, { UploadedFile } from "../../components/file-upload/file-upload";
import DeleteFileModal from "../../modals/delete-file-modal/delete-file-modal";
import CustomPhoneInput from "../../components/custom-phone-input/custom-phone-input";
import AddressForm from "../../components/address-form/address-form";
import AvatarUploader from "./components/avatar-uploader";
import CustomButton from "../../components/custom-button/custom-button";
import { SPACES_ENDPOINT } from "../../utils/constants";
import InvestorBankDetailsSection, { BankDetailsForm } from "./components/investor-bank-details-section/investor-bank-details-section";

const InvestorDetailPage = () => {
    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        investor,
        avatar,
        isInitialLoaded,
        isLoading,
        setInvestor,
        setAvatar,
        setIsInitalLoaded,
        setIsLoading,
    } = useInvestorDetailStore();

    // States
    const [investorOriginal, setInvestorOriginal] = useState<IInvestor | null>(null);
    const [emailError, setEmailError] = useState<string>("");
    const [passportFiles, setPassportFiles] = useState<UploadedFile[]>([]);
    const [proofOfAddressFile, setProofOfAddressFile] = useState<UploadedFile | null>(null);
    const [avatarFile, setAvatarFile] = useState<File | null>(null);
    const [isDeleteFileModalVisible, setIsDeleteFileModalVisible] = useState<IDocument | null>(null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    useEffect(() => {
        const loadInvestor = async () => {
            try {
                setIsLoading(true);
                const investor = await fetchInvestor();
                setInvestor(investor);
                if (investor.avatar && investor.avatar.length > 0) {
                    setAvatar(`${SPACES_ENDPOINT}${investor.avatar}`);
                }
                setInvestorOriginal(investor);
                setIsInitalLoaded(true);
            } catch (error) {
                console.log(`Error while fetching investor data:`, error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
        loadInvestor();
    }, []);

    const onPassportFileSelected = (selectedFile: UploadedFile) => {
        setPassportFiles(prevFiles => [...prevFiles, selectedFile]);
    };

    const onProofOfAddressFileSelected = (selectedFile: UploadedFile) => {
        setProofOfAddressFile(selectedFile);
    };

    const onDeleteInvestorDocument = useCallback(async (document: IDocument) => {
        if (investor) {
            if (document.document_type === "passport") {
                const passport = investor.passport.filter(file => file.document_id !== document.document_id);
                setInvestor({ ...investor, passport });
                setInvestorOriginal({ ...investor, passport });
            }
            else if (document.document_type === "proof-of-address") {
                const proofOfAddress = investor.proofOfAddress.filter(file => file.document_id !== document.document_id);
                setInvestor({ ...investor, proofOfAddress });
                setInvestorOriginal({ ...investor, proofOfAddress });
            }
        }
    }, [investor]);

    const onHandleUpdate = async () => {
        if (investor) {
            let valid = true;
            setEmailError('');

            // Basic email validation
            if (!investor.email) {
                setEmailError('E-Mail darf nicht leer sein.');
                valid = false;
            } else if (!/^\S+@\S+\.\S+$/.test(investor.email)) {
                setEmailError('Bitte gib eine gültige E-Mail Adresse ein.');
                valid = false;
            }

            if (valid) {
                try {
                    setIsUpdating(true);

                    // Update investor data
                    if (!_.isEqual(investor, investorOriginal)) {
                        await updateInvestor(investor.email, investor.phone_number ?? "");
                        setInvestorOriginal({ ...investor, email: investor.email, phone_number: investor.phone_number });
                    }

                    // Upload new investor avatar
                    if (avatarFile) {
                        const updatedAvatar = await uploadInvestorAvatar(avatarFile);
                        setInvestor({ ...investor, avatar: updatedAvatar });
                        setInvestorOriginal({ ...investor, avatar: updatedAvatar });
                        setAvatarFile(null);
                        setAvatar(`${SPACES_ENDPOINT}${updatedAvatar}`);
                    }

                    // Upload new passport documents
                    if (passportFiles.length > 0) {
                        const newDocuments = await uploadInvestorPassport(passportFiles.map(file => file.file));
                        setPassportFiles([]);

                        const newPassportFiles = investor.passport;
                        newPassportFiles.push(...newDocuments);
                        setInvestor({ ...investor, passport: newPassportFiles });
                        setInvestorOriginal({ ...investor, passport: newPassportFiles });
                    }

                    // Upload new proof of address file
                    if (proofOfAddressFile) {
                        const newDocuments = await uploadInvestorProofOfAddress([proofOfAddressFile.file]);
                        setProofOfAddressFile(null);

                        const newProofOfAddressFiles = investor.proofOfAddress;
                        newProofOfAddressFiles.push(...newDocuments);
                        setInvestor({ ...investor, proofOfAddress: newProofOfAddressFiles });
                        setInvestorOriginal({ ...investor, proofOfAddress: newProofOfAddressFiles });
                    }

                    showAlert("success", "Daten erfolgreich gespeichert!");
                } catch (error) {
                    console.log("Error while updating profile:", error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsUpdating(false);
                }
            }
        }
    }

    const dataHasBeenChanged = useMemo(() => {
        return !_.isEqual(investor, investorOriginal) || passportFiles.length > 0 || proofOfAddressFile || avatarFile;
    }, [investor, investorOriginal, passportFiles, proofOfAddressFile, avatarFile]);

    return (
        <div className="no-select">
            {isLoading && !isInitialLoaded ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <div className="spinner-border text-primary" role="status" />
                </div>
            ) : (
                investor && (
                    <>
                        {/* SECTION TITLE */}
                        <div className="d-flex justify-content-between align-items-center">
                            {investor && (
                                <>
                                    <div className="d-flex justify-content-items align-items-center">
                                        <AvatarUploader
                                            className="me-4"
                                            avatarUrl={avatar}
                                            onAvatarSelected={(avatarFile: File, avatarUrl: string) => {
                                                setAvatarFile(avatarFile);
                                                setAvatar(avatarUrl);
                                            }}
                                        />
                                        <div>
                                            <h4 className="m-0">Hallo, {investor.first_name} {investor.last_name}</h4>
                                            <p className="d-none d-md-block" style={{ margin: 0, fontSize: 14 }}>Hoffentlich bringt der heutige Tag spannende neue Möglichkeiten!</p>
                                        </div>
                                    </div>
                                    <CustomButton
                                        className="d-none d-md-block"
                                        title="Speichern"
                                        isLoading={isUpdating}
                                        disabled={!dataHasBeenChanged || isLoading}
                                        onClick={onHandleUpdate}
                                    />
                                </>
                            )}
                        </div>

                        <div className="row mt-4 gy-4">
                            <div className="col-12 col-md-6">
                                <h4>Allgemeine Informationen</h4>
                                <div className="white-card">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <CustomInputField
                                                id="email"
                                                type="email"
                                                label="E-Mail"
                                                maxLength={140}
                                                value={investor.email}
                                                errorMessage={emailError}
                                                onChange={(text: string) => {
                                                    setInvestor({ ...investor, email: text });
                                                    setEmailError("");
                                                }}
                                                onClear={() => {
                                                    setInvestor({ ...investor, email: "" });
                                                    setEmailError("");
                                                }}
                                                placeholder="max@mustermann.de"
                                                required
                                            />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <CustomPhoneInput
                                                id="phone-number"
                                                defaultCountry="DE"
                                                labelOnTop
                                                value={investor.phone_number ?? undefined}
                                                onChange={(number: string) => {
                                                    setInvestor({ ...investor, phone_number: number });
                                                }}
                                                onClear={() => {
                                                    setInvestor({ ...investor, phone_number: "" });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <h4>Adresse</h4>
                                <div className="white-card">
                                    <AddressForm
                                        address={investor.address}
                                        onAddressChanged={(address) => {
                                            setInvestor({ ...investor, address });
                                        }}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                        {/* INVESTOR BANK DETAILS */}
                        <div className="mt-5">
                            <InvestorBankDetailsSection
                                bank_details={investor.bank_details.length > 0 ? investor.bank_details[0] : null}
                            />
                        </div>

                        <div className="row mt-5 gy-4">
                            <div className="col-12 col-md-6">
                                <h4>Reisepass</h4>
                                <div className="white-card">
                                    {investor.passport.length <= 0 && (
                                        <FileUpload id="investor-passport" allowedTypes='.pdf,image/*' onFileSelected={onPassportFileSelected} />
                                    )}
                                    {passportFiles.map((file, index) => {
                                        return (
                                            <FileCard
                                                index={`uploaded-passport-file-card-${file.filename}`}
                                                fileName={file.filename}
                                                fileSize={file.filesize}
                                                progress={100}
                                                uploaded
                                                onClickRemove={() => {
                                                    setPassportFiles(passportFiles.filter((_, idx) => idx !== index));
                                                }}
                                            />
                                        )
                                    })}
                                    {investor.passport.map(file => {
                                        return (
                                            <FileCard
                                                index={`passport-file-card-${file.document_id}`}
                                                documentId={file.document_id}
                                                fileName={file.file_name}
                                                filePath={file.file_path}
                                                validUntil={file.valid_until}
                                                progress={100}
                                                uploaded
                                            //onClickRemove={() => setIsDeleteFileModalVisible(file)}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <h4>Adressnachweis</h4>
                                <div className="white-card">
                                    {investor.proofOfAddress.length === 0 && !proofOfAddressFile && (
                                        <FileUpload id="investor-proof-of-address" allowedTypes='.pdf,image/*' onFileSelected={onProofOfAddressFileSelected} />
                                    )}
                                    {proofOfAddressFile && (
                                        <FileCard
                                            index={"uploaded-proof-of-address-file-card"}
                                            fileName={proofOfAddressFile.filename}
                                            fileSize={proofOfAddressFile.filesize}
                                            progress={100}
                                            uploaded
                                            onClickRemove={() => setProofOfAddressFile(null)}
                                        />
                                    )}
                                    {investor.proofOfAddress.map(file => {
                                        return (
                                            <FileCard
                                                index={`proof-of-address-file-card-${file.document_id}`}
                                                documentId={file.document_id}
                                                fileName={file.file_name}
                                                filePath={file.file_path}
                                                validUntil={file.valid_until}
                                                progress={100}
                                                uploaded
                                            //onClickRemove={() => setIsDeleteFileModalVisible(file)}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <h4 className="mt-5">({investor.documents.length}) Verfügbare Dokumente</h4>
                        <p>Du wirst per E-Mail benachrichtigt sobald neue Dokumente verfügbar sind.</p>
                        <div className="white-card h-100 mt-3">
                            {investor.documents.length === 0 ? (
                                <p className="my-0">Es wurden noch keine Dokumente für dich bereitgestellt.</p>
                            ) : (
                                <div className="row gy-3">
                                    {investor.documents.map((document) => {
                                        return (
                                            <div className="col-12 col-md-6">
                                                <FileCard
                                                    index={`document-file-card-${document.document_id}`}
                                                    documentId={document.document_id}
                                                    fileName={document.file_name}
                                                    filePath={document.file_path}
                                                    validUntil={document.valid_until}
                                                    progress={100}
                                                    uploaded />
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                        <CustomButton
                            className="mt-4 d-md-none w-100"
                            title="Speichern"
                            isLoading={isUpdating}
                            disabled={!dataHasBeenChanged || isLoading}
                            onClick={onHandleUpdate}
                        />
                    </>
                )
            )}
            {isDeleteFileModalVisible && (
                <DeleteFileModal
                    document_id={isDeleteFileModalVisible.document_id}
                    onClose={(deleted: boolean) => {
                        if (deleted) {
                            onDeleteInvestorDocument(isDeleteFileModalVisible);
                        }
                        setIsDeleteFileModalVisible(null);
                    }}
                />
            )}
        </div>
    );
};

export default InvestorDetailPage;
