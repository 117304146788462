import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { ICourseModule, ICourseModuleItem } from "../services/courseService";

const STORAGE_NAME = "course-storage";

interface CourseState {
  modules: ICourseModule[];
  moduleItems: ICourseModuleItem[];
  currentPage: number;
  selectedModuleItem: ICourseModuleItem | null;
  selectedModuleId: number;
  isLoading: boolean;
  isInitialLoaded: boolean;
  moreDataAvailable: boolean;
  setModules: (modules: ICourseModule[]) => void;
  setModuleItems: (items: ICourseModuleItem[]) => void;
  setCurrentPage: (page: number) => void;
  setSelectedModuleItem: (moduleItem: ICourseModuleItem | null) => void;
  setSelectedModuleId: (moduleId: number) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsInitialLoaded: (isInitialLoaded: boolean) => void;
  setMoreDataAvailable: (moreData: boolean) => void;
  reset: () => void;
}

const useCourseStore = create<CourseState>()(
  devtools(
    persist(
      (set) => ({
        modules: [],
        moduleItems: [],
        currentPage: 1,
        selectedModuleItem: null,
        selectedModuleId: 1,
        isLoading: true,
        isInitialLoaded: false,
        moreDataAvailable: true,
        setModules: (modules: ICourseModule[]) => set({ modules }),
        setModuleItems: (items: ICourseModuleItem[]) =>
          set({ moduleItems: items }),
        setCurrentPage: (page: number) => set({ currentPage: page }),
        setSelectedModuleId: (moduleId: number) =>
          set({ selectedModuleId: moduleId }),
        setSelectedModuleItem: (moduleItem: ICourseModuleItem | null) =>
          set({ selectedModuleItem: moduleItem }),
        setIsLoading: (isLoading: boolean) => set({ isLoading: isLoading }),
        setIsInitialLoaded: (isInitialLoaded: boolean) =>
          set({ isInitialLoaded: isInitialLoaded }),
        setMoreDataAvailable: (moreData: boolean) =>
          set({ moreDataAvailable: moreData }),
        reset: () => {
          set({
            modules: [],
            selectedModuleId: 1,
            isLoading: true,
            isInitialLoaded: false,
          });
        },
      }),
      {
        name: STORAGE_NAME,
      }
    )
  )
);

export default useCourseStore;
