// GoogleMapCard.tsx

import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { APIProvider, Map, MapCameraChangedEvent, MapCameraProps } from '@vis.gl/react-google-maps';
import styles from './google-map-card.module.css';

// Internal helper function
const parseGoogleMapsUrl = (url: string): { lat: number, lng: number } | null => {
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = url.match(regex);
    if (match) {
        return {
            lat: parseFloat(match[1]),
            lng: parseFloat(match[2]),
        };
    }
    return null;
};

interface GoogleMapCardProps {
    mapContainerStyle?: CSSProperties | undefined;
    mapsUrl: string;
    radius: number;
}

const INITIAL_CAMERA = {
    center: { lat: 40.7, lng: -74 },
    zoom: 12
};

const GoogleMapCard: React.FC<GoogleMapCardProps> = ({ mapContainerStyle, mapsUrl, radius }) => {
    const [location, setLocation] = useState<{ lat: number, lng: number } | null>(null);
    const [cameraProps, setCameraProps] = useState<MapCameraProps>(INITIAL_CAMERA);

    useEffect(() => {
        const loc = parseGoogleMapsUrl(mapsUrl);
        setLocation(loc);
        if (loc) {
            setCameraProps({ center: loc, zoom: radius });
        }
    }, [mapsUrl]);

    const handleCameraChange = useCallback((ev: MapCameraChangedEvent) => {
        setCameraProps(ev.detail);
    }, []);

    if (!location) {
        return <div>Invalid Google Maps URL - The team has been notified!</div>;
    }

    return (
        <div className={styles.googleMapCard}>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}>
                <Map
                    mapId="7779b93398f5ccc0"
                    style={mapContainerStyle || { width: '100%', height: '300px' }}
                    center={cameraProps.center}
                    zoom={cameraProps.zoom}
                    onCameraChanged={handleCameraChange}
                >
                </Map>
            </APIProvider>
        </div>
    );
};

export default GoogleMapCard;
