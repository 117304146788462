import '../login-page/login-page.css';

import { useState } from "react";
import DGLogo from "../../assets/images/dg-logo.png";
import HavensGardenImage from "../../assets/images/haven-gardens.webp";
import TheVybeImage from "../../assets/images/the-vybe.webp";
import CustomInputField from '../../components/custom-input-field/custom-input-field';
import CustomButton from '../../components/custom-button/custom-button';
import { useNavigate } from 'react-router-dom';
import SixDigitCodeInput from './components/six-digit-code';
import { checkForgotPasswordCode, forgotPassword, resetPassword } from '../../services/authService';

const ResetPasswordPage = () => {
    const navigate = useNavigate();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [resetPasswordError, setResetPasswordError] = useState('');
    const [sentCode, setSentCode] = useState<boolean>(false);
    const [isValidCode, setIsValidCode] = useState<boolean>(false);
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [passwordReseted, setPasswordReseted] = useState<boolean>(false);

    const handleForgotPassword = async () => {
        let valid = true;
        setEmailError("");
        setResetPasswordError("");

        // Basic email validation
        if (!email) {
            setEmailError('E-Mail darf nicht leer sein.');
            valid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email)) {
            setEmailError('Bitte gib eine gültige E-Mail Adresse ein.');
            valid = false;
        }

        if (valid) {
            try {
                setIsLoading(true);
                const sentEmail = await forgotPassword(email);
                setSentCode(sentEmail);
            } catch (error) {
                console.log('Error while send forgot password email:', error);
                // @ts-ignore
                setResetPasswordError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleCheckCode = async () => {
        let valid = true;
        setResetPasswordError('');

        // Basic email validation
        if (!verificationCode || verificationCode.length !== 6) {
            setResetPasswordError('Code darf nicht leer sein.');
            valid = false;
        }

        if (valid) {
            try {
                setIsLoading(true);
                const isValid = await checkForgotPasswordCode(verificationCode);
                if (isValid) {
                    setIsValidCode(isValid);
                } else {
                    setResetPasswordError("Der angegebene Code ist ungültig.");
                }
            } catch (error) {
                console.log('Error while checking forgot password code:', error);
                // @ts-ignore
                setResetPasswordError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleResetPassword = async () => {
        let valid = true;
        setPasswordError("");
        setResetPasswordError("");

        // Basic password validation
        if (!password) {
            setPasswordError('Passwort darf nicht leer sein.');
            valid = false;
        }

        if (valid) {
            try {
                setIsLoading(true);
                const passwordReset = await resetPassword(verificationCode, password);
                setPasswordReseted(passwordReset);
            } catch (error) {
                console.log('Error while change password:', error);
                // @ts-ignore
                setResetPasswordError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="page-container-full">
            <div className="row">
                <div className="col-md-6 d-none d-md-flex flex-column left-panel align-items-center justify-content-center">
                    <div style={{ marginBottom: 100, width: '100%' }}>
                        <img src={DGLogo} width={200} className="mx-auto d-block" alt="DG Logo" />
                        <h4 className="text-center mt-4">Investorenportal</h4>
                        <div className="w-100 d-flex justify-content-center mt-5">
                            <div className="image-container" style={{ position: 'relative' }}>
                                <img className="havens-garden" src={HavensGardenImage} />
                                <img className="the-vybe" src={TheVybeImage} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center right-panel">
                    <img src={DGLogo} width={200} className="mx-auto d-md-none" alt="DG Logo" />
                    <h4 className="text-center mt-4 mb-5 d-md-none">{passwordReseted ? "Passwort geändert" : "Passwort zurücksetzen"}</h4>
                    <h4 className="mb-5 d-none d-md-block">{passwordReseted ? "Passwort geändert" : "Passwort zurücksetzen"}</h4>
                    <div className="login-form">
                        {sentCode ? (
                            isValidCode ? (
                                passwordReseted ? (
                                    <>
                                        <p className="mb-4">Dein Passwort wurde erfolgreich geändert!</p>
                                        <CustomButton
                                            className="w-100"
                                            title="Zurück zur Anmeldung"
                                            isLoading={isLoading}
                                            onClick={() => navigate("/")}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <p>Tippe jetzt dein neues Passwort ein.</p>
                                        <CustomInputField
                                            className="mt-5 mb-4"
                                            id="new-password"
                                            label="Neues Passwort"
                                            type="password"
                                            placeholder="*******"
                                            value={password}
                                            errorMessage={passwordError}
                                            onClear={() => setPassword("")}
                                            onChange={(value: string) => {
                                                setPassword(value);
                                                setPasswordError("");
                                            }}
                                            required
                                        />
                                        {resetPasswordError && (
                                            <p className="ms-2 mb-4 text-danger">{resetPasswordError}</p>
                                        )}
                                        <CustomButton
                                            className="w-100"
                                            title="Passwort ändern"
                                            isLoading={isLoading}
                                            onClick={handleResetPassword}
                                        />
                                    </>
                                )
                            ) : (
                                <>
                                    <p>Tippe unten den 6-Stelligen Code ein den du an deine angegebene E-Mail Adresse erhalten hast.</p>
                                    <SixDigitCodeInput
                                        className="mt-5 mb-5"
                                        onChange={setVerificationCode}
                                    />
                                    {resetPasswordError && (
                                        <p className="ms-2 mb-4 text-danger">{resetPasswordError}</p>
                                    )}
                                    <CustomButton
                                        className="w-100"
                                        title="Code überprüfen"
                                        isLoading={isLoading}
                                        onClick={handleCheckCode}
                                    />
                                </>
                            )
                        ) : (
                            <>
                                <CustomInputField
                                    id="email"
                                    label="E-Mail"
                                    type="email"
                                    placeholder="max@mustermann.de"
                                    value={email}
                                    errorMessage={emailError}
                                    onClear={() => setEmail("")}
                                    onChange={(value: string) => {
                                        setEmail(value);
                                        setEmailError("");
                                    }}
                                    required
                                />
                                {resetPasswordError && (
                                    <p className="ms-2 mb-4 text-danger">{resetPasswordError}</p>
                                )}
                                <CustomButton
                                    className="w-100"
                                    title="Passwort vergessen"
                                    isLoading={isLoading}
                                    onClick={handleForgotPassword}
                                />
                            </>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordPage;
