import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { IUnit } from "../services/unitService";
import { IDocument } from "../services/investorService";

const STORAGE_NAME = "unit-detail-storage";

interface UnitDetailState {
  unit: IUnit | null;
  isLoading: boolean;
  isInitialLoaded: boolean;
  setUnit: (unit: IUnit) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsInitalLoaded: (isInitialLoaded: boolean) => void;
  reset: () => void;
}

const useUnitDetailStore = create<UnitDetailState>()(
  devtools(
    persist(
      (set) => ({
        unit: null,
        isLoading: true,
        isInitialLoaded: false,
        setUnit: (unit: IUnit) => set({ unit }),
        setIsLoading: (isLoading: boolean) => set({ isLoading }),
        setIsInitalLoaded: (isInitialLoaded: boolean) =>
          set({ isInitialLoaded }),
        reset: () => {
          set({
            unit: null,
            isLoading: true,
            isInitialLoaded: false,
          });
        },
      }),
      {
        name: STORAGE_NAME,
      }
    )
  )
);

export default useUnitDetailStore;
