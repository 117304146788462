import './file-card.css';

import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { IoDocumentTextOutline, IoTrashOutline } from 'react-icons/io5';
import { downloadDocument } from '../../services/documentService';
import useAlertStore from '../../stores/alertStore';

type FileCardProps = {
    className?: string;
    index: string;
    documentId?: number;
    fileName: string;
    fileSize?: string;
    filePath?: string;
    progress?: number | null;
    uploaded: boolean;
    validUntil?: string | null;
    onClickRemove?: (() => void) | undefined;
};

const FileCard: React.FC<FileCardProps> = ({
    className,
    index,
    documentId,
    fileName,
    fileSize,
    filePath,
    progress,
    uploaded,
    validUntil,
    onClickRemove = undefined
}) => {
    const progressBarColor = uploaded ? 'file-status-success' : 'file-status-progress';

    // Store
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onDownloadFile = async () => {
        if (documentId) {
            try {
                setIsLoading(true);
                const blob: Blob | null = await downloadDocument(documentId);
                if (blob) {
                    const blobUrl = window.URL.createObjectURL(blob);

                    const anchor = document.createElement('a');
                    anchor.href = blobUrl;
                    anchor.download = fileName;
                    document.body.appendChild(anchor);
                    anchor.click();

                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(anchor);
                }
            } catch (error) {
                console.log("error while downloading file: ", error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const getValidityStatus = (validUntilDate: string) => {
        const currentDate = new Date();
        const validUntil = new Date(validUntilDate);
        const differenceInDays = (validUntil.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);

        if (differenceInDays < 0) {
            return "expired";
        } else if (differenceInDays <= 30) {
            return "expiring-soon";
        } else {
            return "valid";
        }
    };

    const validityStatus = validUntil ? getValidityStatus(validUntil) : "valid";
    const formattedDate = validUntil ? new Date(validUntil).toLocaleDateString() : null;

    return (
        <div key={index} className={`file-status mb-2 p-2 d-flex align-items-center ${className}`}>
            <IoDocumentTextOutline className="me-2" size={24} />
            <div className="flex-grow-1 me-2">
                <div className="d-flex justify-content-between align-items-center">
                    <span className="file-status-name">{fileName}</span>
                    {onClickRemove && (<IoTrashOutline className="file-remove-icon" size={20} onClick={onClickRemove} />)}
                </div>
                {progress && (
                    <div className="d-flex align-items-center mb-2">
                        <div className="progress mt-1 flex-grow-1">
                            <div className={`progress-bar ${progressBarColor}`} role="progressbar" style={{ width: `${progress}%` }} />
                        </div>
                        <div className="ms-2 file-status-indicator">{progress}%</div>
                    </div>
                )}
                <div className="d-flex align-items-center">
                    <div className="d-flex flex-grow-1 justify-content-between align-items-center">
                        {fileSize && (
                            <small className="file-status-size">{fileSize}</small>
                        )}
                        {filePath && documentId && !isLoading && (
                            <small className="file-status-download" onClick={onDownloadFile}>Herunterladen</small>
                        )}
                    </div>
                    {validUntil && (
                        <div className={`valid-until-badge ${validityStatus} ms-auto`}>
                            <span className="badge-status-text">
                                {validityStatus === "valid" && "Gültig"}
                                {validityStatus === "expiring-soon" && "Bald Abgelaufen"}
                                {validityStatus === "expired" && "Abgelaufen"}
                            </span>
                            <span className="badge-date">{formattedDate}</span>
                        </div>
                    )}
                </div>
                {isLoading && (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                )}
            </div>
        </div>
    );
};

export default FileCard;
