// SixDigitCodeInput.tsx
import './six-digit-code.css';

import React, { useState, useRef, useEffect } from 'react';

interface SixDigitCodeInputProps {
    className?: string | undefined;
    onChange: (code: string) => void;
}

const SixDigitCodeInput: React.FC<SixDigitCodeInputProps> = ({ className, onChange }) => {
    const [code, setCode] = useState(Array(6).fill(''));
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, 6);
    }, []);

    useEffect(() => {
        onChange(code.join(''));
    }, [code, onChange]);

    const handleChange = (digit: string, index: number) => {
        const newCode = [...code];
        newCode[index] = digit.substring(0, 1); // Get only the first character
        setCode(newCode);

        // Move focus to the next input if not on the last input
        if (index < 5 && digit) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedData = e.clipboardData.getData('text').split('').filter((_, index) => index < 6);
        if (pastedData.length === 6) {
            setCode(pastedData);
            inputRefs.current[5]?.focus();
            e.preventDefault();
        }
    };

    return (
        <div className={`input-field six-digit-code-input ${className ?? ""}`}>
            {code.map((digit, index) => (
                <React.Fragment key={index}>
                    <input
                        ref={el => inputRefs.current[index] = el}
                        type="text"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onPaste={index === 0 ? handlePaste : undefined}
                        className="code-input"
                    />
                    {index === 2 && <span className="divider">-</span>}
                </React.Fragment>
            ))}
        </div>
    );
};

export default SixDigitCodeInput;
