import './custom-phone-input.css';

import React from 'react';
import { BsXCircle } from 'react-icons/bs';
import PhoneInputWithCountrySelect, { Country, Value } from 'react-phone-number-input';

type Props = {
    id: string;
    className?: string;
    value: Value | undefined;
    defaultCountry?: Country;
    errorMessage?: string;
    labelOnTop?: boolean;
    onChange: (number: string) => void;
    onClear: () => void;
};

const CustomPhoneInput: React.FC<Props> = ({
    id,
    className = "",
    value,
    defaultCountry,
    errorMessage,
    labelOnTop = false,
    onChange,
    onClear
}) => {
    const labelClasses = labelOnTop ? 'phone-input-label-on-top' : 'col-sm-5 col-form-label';
    const inputWrapperClasses = labelOnTop ? 'phone-input-wrapper-on-top' : 'col-sm-7';

    return (
        <div className={`phone-input-field ${labelOnTop ? '' : 'row'} ${className}`}>
            <label htmlFor={id} className={labelClasses}>
                Handynummer
            </label>
            <div className={inputWrapperClasses}>
                <div className="input-wrapper" style={labelOnTop ? { height: 50 } : {}}>
                    <PhoneInputWithCountrySelect
                        id={id}
                        defaultCountry={defaultCountry}
                        value={value}
                        onChange={onChange}
                        className="phone-input"
                    />
                    {value && (
                        <BsXCircle className="clear-icon" onClick={onClear} />
                    )}
                </div>
                {errorMessage && (
                    <small className="ms-2 text-danger">{errorMessage}</small>
                )}
            </div>
        </div>
    );
};

export default CustomPhoneInput;
