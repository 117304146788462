// searchStore.ts
import create from "zustand";

interface SearchStoreState {
  search: string;
  setSearch: (value: string) => void;
}

const useSearchStore = create<SearchStoreState>((set) => ({
  search: "",
  setSearch: (value: string) => set({ search: value }),
}));

export default useSearchStore;
