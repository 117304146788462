import './sidebar.css';

import React, { useEffect, useState } from 'react';
import { IoConstruct, IoConstructOutline, IoExitOutline, IoHelpCircle, IoHelpCircleOutline, IoHome, IoHomeOutline, IoPerson, IoPersonOutline, IoSchool, IoSchoolOutline, } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';
import { IconType } from 'react-icons';
import DGLogo from '../../assets/images/dg-logo.png';
import { logout } from '../../services/authService';
import useConstructionUpdateStore from '../../stores/constructionUpdateStore';
import useInvestorDetailStore from '../../stores/investorDetailStore';
import useUnitDetailStore from '../../stores/unitDetailStore';

interface MenuItem {
    id: number,
    name: string,
    activeIcon: IconType,
    inactiveIcon: IconType,
    path: string,
    isVisible: boolean;
    isActivated: boolean;
}

const MENU_ITEMS: MenuItem[] = [
    { id: 1, name: 'Mein Profil', activeIcon: IoPerson, inactiveIcon: IoPersonOutline, path: '/', isVisible: true, isActivated: true },
    { id: 2, name: 'Meine Immobilien', activeIcon: IoHome, inactiveIcon: IoHomeOutline, path: '/my-units', isVisible: true, isActivated: true },
    { id: 3, name: 'Baufortschritte', activeIcon: IoConstruct, inactiveIcon: IoConstructOutline, path: '/construction-updates', isVisible: true, isActivated: true },
    //{ id: 4, name: 'After Sale Kurs', activeIcon: IoSchool, inactiveIcon: IoSchoolOutline, path: '/course', isVisible: true, isActivated: true },
    { id: 5, name: 'FAQ', activeIcon: IoHelpCircle, inactiveIcon: IoHelpCircleOutline, path: '/faq', isVisible: true, isActivated: true },
];

interface SidebarProps {
    isSidebarVisible: boolean;
    toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebarVisible, toggleSidebar }) => {
    const location = useLocation();

    // Stores
    const resetInvestoreDetailStore = useInvestorDetailStore(state => state.reset);
    const resetUnitDetailStore = useUnitDetailStore(state => state.reset);
    const resetConstructionUpdateStore = useConstructionUpdateStore(state => state.reset);

    // States
    const [activeItem, setActiveItem] = useState<string>('Mein Profil');

    useEffect(() => {
        const findActiveItem = () => {
            const active = MENU_ITEMS.find(item => location.pathname === item.path);
            return active ? active.name : '';
        };

        setActiveItem(findActiveItem());
    }, [location]);

    const onClickLogout = async () => {
        await logout();

        resetConstructionUpdateStore();
        resetInvestoreDetailStore();
        resetUnitDetailStore();
    }

    const handleItemClick = (item: MenuItem) => {
        if (!item.isActivated) return;

        if (activeItem !== item.name) {
            setActiveItem(item.name);
            toggleSidebar();
        } else {
            window.location.reload();
        }
    };

    return (
        <div className={`col-lg-2 sidebar ${isSidebarVisible ? 'visible' : ''}`} id="sidebar">
            <div className="text-center">
                <img src={DGLogo} className="mt-4" />
                <h4 className="mt-3">Investor-Portal (BETA)</h4>
            </div>
            <div className="divider mt-3" />
            <ul className="nav flex-column mt-4">
                {MENU_ITEMS.filter(menuItem => menuItem.isVisible).map((item) => {
                    const IconComponent = activeItem === item.name ? item.activeIcon : item.inactiveIcon;

                    // Render regular link for other items
                    return (
                        <li
                            key={item.name}
                            className={`nav-item ${activeItem === item.name ? 'active' : ''}`}
                        >
                            <Link
                                to={item.isActivated ? item.path : '#'}
                                className={`nav-link ${!item.isActivated ? 'deactivated' : ''}`}
                                onClick={() => handleItemClick(item)}
                            >
                                <span className="icon me-2">
                                    <IconComponent />
                                </span>
                                {item.name}
                            </Link>
                        </li>
                    );
                })}

                <li className="nav-item logout-button mt-auto mb-3">
                    <Link
                        to="/login"
                        className="nav-link logout"
                        onClick={onClickLogout}
                    >
                        <span className="icon me-2" >
                            <IoExitOutline />
                        </span>
                        Abmelden
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
