import './alert.css';

import React, { useEffect } from 'react';
import useAlertStore from '../../stores/alertStore';

const Alert: React.FC = () => {
  const { type, message, visible, hideAlert } = useAlertStore();

  // Scroll to top to show alert
  useEffect(() => {
    if (visible) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [visible]);

  if (!visible) return null;

  const alertClass = `custom-alert alert-${type} sticky-top mb-4`;

  return (
    <div className={alertClass} role="alert">
      {/*<strong>{type.charAt(0).toUpperCase() + type.slice(1)}!</strong> {message}*/}
      {message}
      <button type="button" className="close-button" aria-label="Close" onClick={hideAlert}>
        &times;
      </button>
    </div>
  );
};

export default Alert;
