import React from 'react';
import Alert from '../components/alert/alert';

interface PageContainerWrapperProps {
    id: string;
    children: React.ReactNode;
}

const PageContainerWrapper: React.FC<PageContainerWrapperProps> = ({ id, children }) => {
    return (
        <div className="page-container" id={id}>
            <Alert />
            {children}
        </div>
    );
};

export default PageContainerWrapper;
