// alertStore.ts
import create from "zustand";

interface AlertState {
  type: "warning" | "error" | "success" | "info" | "";
  message: string;
  visible: boolean;
  showAlert: (type: AlertState["type"], message: string) => void;
  hideAlert: () => void;
}

const useAlertStore = create<AlertState>((set) => ({
  type: "",
  message: "",
  visible: false,
  showAlert: (type, message) => set({ type, message, visible: true }),
  hideAlert: () => set({ type: "", message: "", visible: false }),
}));

export default useAlertStore;
