import React, { useState } from 'react';
import { FaFileDownload, FaMoneyBillWave, FaCalendarAlt } from 'react-icons/fa';
import { ILease, downloadLeaseDocument } from '../../../../services/leaseService';
import styles from './lease-details.module.css';
import { Button, Collapse } from 'react-bootstrap';
import { formatMoney, getFormattedDate } from '../../../../utils/helpers';

interface LeaseDetailsProps {
    lease: ILease | null;
    history: ILease[];
}

const LeaseDetails: React.FC<LeaseDetailsProps> = ({ lease, history }) => {
    const [open, setOpen] = useState(false);

    const onClickDownloadLeaseDocument = async (lease: ILease) => {
        if (lease) {
            try {
                const leaseDocument = lease.documents.length > 0 ? lease.documents[0] : null;
                if (leaseDocument) {
                    const blob: Blob | null = await downloadLeaseDocument(lease.lease_id, leaseDocument.document_id);
                    if (blob) {
                        const blobUrl = window.URL.createObjectURL(blob);

                        const anchor = document.createElement('a');
                        anchor.href = blobUrl;
                        anchor.download = leaseDocument.file_name;
                        document.body.appendChild(anchor);
                        anchor.click();

                        window.URL.revokeObjectURL(blobUrl);
                        document.body.removeChild(anchor);
                    }
                }
            } catch (error) {
                console.log('Error while downloading lease document: ', error);
            }
        }
    };

    return (
        <div className={styles.leaseDetailsSection}>
            {/* Current Tenant */}
            <h4 className={styles.sectionTitle}>Laufender Mietvertrag</h4>
            <div className={styles.leaseDetailsCard}>
                {lease ? (
                    <>
                        <div className="row">
                            <div className="col-12 col-md-6 col-xl-4">
                                <p className={styles.infoLabel}>Mieter</p>
                                <p className={styles.infoValue}>{lease.tenant.first_name} {lease.tenant.last_name}</p>
                                <div className={styles.divider}></div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <p className={styles.infoLabel}>Einzugsdatum</p>
                                <p className={styles.infoValue}>{getFormattedDate(lease.start_date)}</p>
                                <div className={styles.divider}></div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <p className={styles.infoLabel}>Auszugsdatum</p>
                                <p className={styles.infoValue}>{getFormattedDate(lease.end_date)}</p>
                                <div className={styles.divider}></div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <p className={styles.infoLabel}>Miete (Jährlich)</p>
                                <p className={styles.infoValue}>{formatMoney(lease.total_rent_amount)} AED</p>
                                <div className={styles.divider}></div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <p className={styles.infoLabel}>Kaution</p>
                                <p className={styles.infoValue}>{formatMoney(lease.deposit_amount)} AED</p>
                                <div className={styles.divider}></div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <p className={styles.infoLabel}>Zahlungsfrequenz</p>
                                <p className={styles.infoValue}>Vierteljährlich</p>
                                <div className={styles.divider}></div>
                            </div>

                            {/* Download Section */}
                            <div className="col-12 col-md-6 col-xl-4">
                                <p className={styles.infoLabel}>Mietvertrag</p>
                                <Button onClick={() => onClickDownloadLeaseDocument(lease)} variant="link" className={styles.downloadLink}>
                                    <FaFileDownload /> Herunterladen
                                </Button>
                                <div className={styles.divider}></div>
                            </div>

                            <div className="col-12 col-md-6 col-xl-4">
                                <p className={styles.infoLabel}>Reisepass</p>
                                <Button onClick={() => onClickDownloadLeaseDocument(lease)} variant="link" className={styles.downloadLink}>
                                    <FaFileDownload /> Herunterladen
                                </Button>
                                <div className={styles.divider}></div>
                            </div>
                        </div>
                        {/* Collapsible History Section */}
                        {history.length > 0 && (
                            <>
                                <Button
                                    onClick={() => setOpen(!open)}
                                    aria-controls="lease-history-collapse"
                                    aria-expanded={open}
                                    variant="link"
                                    className={styles.showMoreButton}
                                >
                                    {open ? 'Verlauf ausblenden' : 'Verlauf anzeigen'}
                                </Button>
                                <Collapse in={open}>
                                    <div id="lease-history-collapse">
                                        {history.map((previousLease, index) => (
                                            <div key={index} className="row mt-3">
                                                <div className="col-12 col-md-6 col-xl-4">
                                                    <p className={styles.infoLabel}>Mieter</p>
                                                    <p className={styles.infoValue}>{previousLease.tenant.first_name} {previousLease.tenant.last_name}</p>
                                                    <div className={styles.divider}></div>
                                                </div>
                                                <div className="col-12 col-md-6 col-xl-4">
                                                    <p className={styles.infoLabel}>Einzugsdatum</p>
                                                    <p className={styles.infoValue}>{getFormattedDate(previousLease.start_date)}</p>
                                                    <div className={styles.divider}></div>
                                                </div>
                                                <div className="col-12 col-md-6 col-xl-4">
                                                    <p className={styles.infoLabel}>Auszugsdatum</p>
                                                    <p className={styles.infoValue}>{getFormattedDate(previousLease.end_date)}</p>
                                                    <div className={styles.divider}></div>
                                                </div>
                                                <div className="col-12 col-md-6 col-xl-4">
                                                    <p className={styles.infoLabel}>Miete (Jährlich)</p>
                                                    <p className={styles.infoValue}>{formatMoney(previousLease.total_rent_amount)} AED</p>
                                                    <div className={styles.divider}></div>
                                                </div>
                                                <div className="col-12 col-md-6 col-xl-4">
                                                    <p className={styles.infoLabel}>Kaution</p>
                                                    <p className={styles.infoValue}>{formatMoney(previousLease.deposit_amount)} AED</p>
                                                    <div className={styles.divider}></div>
                                                </div>
                                                <div className="col-12 col-md-6 col-xl-4">
                                                    <p className={styles.infoLabel}>Zahlungsfrequenz</p>
                                                    <p className={styles.infoValue}>Vierteljährlich</p>
                                                    <div className={styles.divider}></div>
                                                </div>

                                                {/* Download Section */}
                                                <div className="col-12 col-md-6 col-xl-4">
                                                    <p className={styles.infoLabel}>Mietvertrag</p>
                                                    <Button onClick={() => onClickDownloadLeaseDocument(previousLease)} variant="link" className={styles.downloadLink}>
                                                        <FaFileDownload /> Herunterladen
                                                    </Button>
                                                    <div className={styles.divider}></div>
                                                </div>

                                                <div className="col-12 col-md-6 col-xl-4">
                                                    <p className={styles.infoLabel}>Reisepass</p>
                                                    <Button onClick={() => onClickDownloadLeaseDocument(previousLease)} variant="link" className={styles.downloadLink}>
                                                        <FaFileDownload /> Herunterladen
                                                    </Button>
                                                    <div className={styles.divider}></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Collapse>
                            </>
                        )}
                    </>
                ) : (
                    <p>Keine aktuellen Mietinformationen verfügbar.</p>
                )}
            </div>
        </div>
    );
};

export default LeaseDetails;
