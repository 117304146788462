import '../base-modal.css';
import './info-modal.css';

import React from 'react';
import packageInfo from '../../../package.json';

interface ModalProps {
    onClose: (deleted: boolean) => void;
}

const InfoModal: React.FC<ModalProps> = ({ onClose }) => {
    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">Fragen oder Probleme?</h3>
                <p>Version: v{packageInfo.version} | Build: 5</p>
                <p>Bei Fragen oder Probleme wende dich bitte per E-Mail an <a href="mailto:social@danielgarofoli.com">social@danielgarofoli.com</a>.</p>
                <div className="text-end mt-4">
                    <button className="btn btn-outline-secondary me-2 impressum-btn" onClick={() => window.open('https://www.danielgarofoli.com/impressum', '_blank')}>Impressum</button>
                    <button className="btn btn-dark close-btn" onClick={() => onClose(false)}>Schließen</button>
                </div>
            </div>
        </div>
    );
};

export default InfoModal;
