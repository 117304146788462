import { useRouteError } from "react-router-dom";
import NotFoundAnim from "../assets/anims/not-found-anim.json";
import Lottie from "lottie-react";

interface RouteError extends Error {
    statusText?: string;
    message: string;
}

export default function ErrorPage() {
    const error = useRouteError() as RouteError;
    console.error(error);

    return (
        <div id="error-page">
            <Lottie animationData={NotFoundAnim} loop={true} style={{ height: "100vh" }} />
        </div>
    );
}
