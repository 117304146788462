import './show-picture-modal.css';
import { IoClose } from 'react-icons/io5';
import { SPACES_ENDPOINT } from '../../utils/constants';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface ModalProps {
    image: string;
    onClose: () => void;
    onNext: () => void;
    onPrevious: () => void;
}

const PictureModal: React.FC<ModalProps> = ({ image, onClose, onNext, onPrevious }) => {
    return (
        <div className="show-picture-modal-backdrop">
            <div className="show-picture-modal-content">
                <button className="carousel-control-prev show-picture-nav-button" onClick={onPrevious}>
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <div className="show-picture-modal-image-wrapper">
                    <IoClose className="show-picture-close-icon" onClick={onClose} />
                    <LazyLoadImage
                        className="show-picture-modal-image"
                        wrapperClassName="show-picture-modal-image-wrapper"
                        placeholderSrc="https://placehold.co/600x400?text=..."
                        src={`${SPACES_ENDPOINT}${image}`}
                        alt="Unit"
                        effect="opacity"
                        width="100%"
                        draggable={false}
                        onClick={onClose}
                    />
                </div>
                <button className="carousel-control-next show-picture-nav-button" onClick={onNext}>
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
};

export default PictureModal;
