import { API_URL } from "../utils/constants";
import { IDocument } from "./investorService";
import { ILease } from "./leaseService";

export interface IProperty {
  property_id: number;
  property_name: string;
  property_image: string;
  property_location: string;
  property_map_link: string;
  created_at: string;
}

export interface IPicture {
  picture_id: number;
  picture_path: string;
  created_at: string;
}

export interface IUnitOwner {
  unit_owner_id: number;
  shares: number;
  purchase_price: number;
  created_at: string;
}

export enum UnitPurposeType {
  INVESTMENT = "investment",
  SELF_INTEREST = "self-interest",
}

export interface IUnit {
  unit_id: number;
  property: IProperty;
  unit_number: string;
  square_feet: number;
  bedroom: number;
  bathroom: number;
  floor: string;
  under_renovation: boolean;
  under_dispute: boolean;
  pm_contract_signed: boolean;
  electricity_no?: string | null;
  municipality_no?: string | null;
  gas_no?: string | null;
  status_id: number;
  purpose_type: UnitPurposeType;
  documents: IDocument[];
  pictures: IPicture[];
  owner: IUnitOwner | null;
  leases: ILease[];
  created_at: string;
  updated_at: string;
}

export const fetchUnits = async (
  page?: number,
  pageSize?: number
): Promise<IUnit[]> => {
  let queryParams = new URLSearchParams();

  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }

  let url = `${API_URL}/units?${queryParams.toString()}`;

  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.units;
};

export const fetchUnit = async (unitId: number): Promise<IUnit> => {
  const response = await fetch(`${API_URL}/units/${unitId}`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};
