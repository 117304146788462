import React from 'react';
import { Navigate } from 'react-router-dom';
import useInvestorDetailStore from '../stores/investorDetailStore';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    // Stores
    const { investor } = useInvestorDetailStore();

    if (!investor) {
        return <Navigate to="/login" replace />;
    }

    return <>
        {children}
    </>;
};

export default ProtectedRoute;
