// AvatarUploader.tsx
import React, { useRef } from 'react';
import { IoAddCircle } from 'react-icons/io5';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface AvatarUploaderProps {
    className?: string;
    avatarUrl: string;
    onAvatarSelected: (file: File, url: string) => void;
}

const AvatarUploader: React.FC<AvatarUploaderProps> = ({ className, avatarUrl, onAvatarSelected }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleIconClick = () => {
        fileInputRef.current?.click();
    };

    const handleAvatarChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                const { scaledFile, scaledUrl } = await scaleDownImage(file, file.type);
                onAvatarSelected(scaledFile, scaledUrl);
            } catch (error) {
                console.error('Error scaling down image:', error);
            }
        }
    };

    const scaleDownImage = (file: File, imageType: string): Promise<{ scaledFile: File; scaledUrl: string }> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const image = new Image();
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const maxSize = 800;
                    let width = image.width;
                    let height = image.height;

                    if (width > height) {
                        if (width > maxSize) {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    } else {
                        if (height > maxSize) {
                            width *= maxSize / height;
                            height = maxSize;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx?.drawImage(image, 0, 0, width, height);
                    const scaledDownUrl = canvas.toDataURL('image/jpeg');

                    const scaledFile = dataURLToFile(scaledDownUrl, file.name, imageType);

                    resolve({ scaledFile, scaledUrl: scaledDownUrl });
                };
                image.src = event.target?.result as string;
            };
            reader.readAsDataURL(file);
        });
    };

    const dataURLToFile = (dataUrl: string, fileName: string, fileType: string): File => {
        const binaryString = atob(dataUrl.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(binaryString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i);
        }

        const file = new File([uint8Array], fileName, { type: fileType });
        return file;
    };
 
    return (
        <div className={`investor-detail-avatar-container ${className ?? ""}`}>
            <LazyLoadImage
                src={avatarUrl}
                alt="Image"
                className="investor-detail-avatar"
                style={{ width: '100%', height: '100%' }}
                effect="opacity"
            />
            <IoAddCircle
                className="position-absolute bottom-0 end-0"
                style={{ fontSize: '26px', cursor: 'pointer' }}
                onClick={handleIconClick}
            />
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleAvatarChange}
                accept="image/*"
            />
        </div>
    );
};

export default AvatarUploader;
