import { API_URL } from "../utils/constants";
import { IDocument } from "./investorService";
import { IUnit } from "./unitService";

export interface ITenant {
  tenant_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string | null;
  birth_date: string;
  gender: string;
  nationality_code: string;
  created_at: string;
  updated_at: string;
  passport: IDocument[];
  documents: IDocument[];
}

export interface ILease {
  lease_id: number;
  unit: IUnit;
  tenant: ITenant;
  start_date: string;
  end_date: string;
  annual_rent_amount: number;
  total_rent_amount: number;
  deposit_amount: number;
  occupants: number;
  remark: string;
  created_at: string;
  updated_at: string;
  documents: IDocument[];
}

export const fetchLeases = async (unitId: number): Promise<ILease[]> => {
  const response = await fetch(`${API_URL}/units/${unitId}/leases`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.units;
};

export const downloadLeaseDocument = async (
  leaseId: number,
  documentId: number
): Promise<Blob | null> => {
  const response = await fetch(
    `${API_URL}/leases/${leaseId}/documents/${documentId}/download`,
    {
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  const fileBlobResponse = await response.blob();
  return fileBlobResponse;
};
