// FaqItem.tsx
import './faq-item.css';

import React, { useState } from 'react';
import { BsPlus, BsDash } from 'react-icons/bs';

interface FaqItemProps {
    title: string;
    content: string;
}

const FaqItem: React.FC<FaqItemProps> = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className={`faq-accordion-item ${isOpen ? 'open' : ''} mb-3`}>
            <div className="faq-accordion-header" onClick={toggle}>
                <div className="faq-title">{title}</div>
                <div className={`${isOpen ? "faq-toggle-icon-active" : "faq-toggle-icon"}`}>
                    {isOpen ? <BsDash size={36} /> : <BsPlus size={36} />}
                </div>
            </div>
            <div className={`faq-accordion-body ${isOpen ? 'show' : ''}`}>
                <div className="faq-content-wrapper">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            </div>
        </div>
    );
};

export default FaqItem;
