import React from 'react';
import CustomInputField from '../custom-input-field/custom-input-field';
import { IAddress } from '../../services/addressService';

interface AddressFormProps {
    address: IAddress;
    disabled?: boolean;
    onAddressChanged: (address: IAddress) => void;
}

const AddressForm: React.FC<AddressFormProps> = ({ address, disabled = false, onAddressChanged }) => {
    const handleChange = (field: string) => (value: string) => {
        onAddressChanged({ ...address, [field]: value });
    };

    const handleClear = (field: string) => () => {
        onAddressChanged({ ...address, [field]: '' });
    };

    return (
        <>
            <div className="row">
                {/* Street Address */}
                <div className="col-md-6">
                    <CustomInputField
                        id="street-address"
                        label="Straße und Hausnummer"
                        placeholder={disabled ? "-" : "Mustermannstr. 32"}
                        value={address.street_address1 ?? ""}
                        onChange={handleChange('street_address1')}
                        onClear={handleClear('street_address1')}
                        disabled={disabled}
                    />
                </div>
                <div className="col-md-6">
                    <CustomInputField
                        id="office"
                        label="Wohnung/Büro"
                        value={address.street_address2 ?? ""}
                        onChange={handleChange('street_address2')}
                        onClear={handleClear('street_address2')}
                        placeholder={disabled ? "-" : "Wohnung, Stockwerk etc."}
                        disabled={disabled}
                    />
                </div>
                {/* City */}
                <div className="col-md-6">
                    <CustomInputField
                        id="city"
                        label="Stadt"
                        placeholder={disabled ? "" : "Karlsruhe"}
                        value={address.city ?? ""}
                        onChange={handleChange('city')}
                        onClear={handleClear('city')}
                        disabled={disabled}
                    />
                </div>
                {/* State */}
                <div className="col-md-6">
                    <CustomInputField
                        id="state"
                        label="Bundesland"
                        placeholder={disabled ? "-" : "NRW"}
                        value={address.state_province_region ?? ""}
                        onChange={handleChange('state_province_region')}
                        onClear={handleClear('state_province_region')}
                        disabled={disabled}
                    />
                </div>
                {/* ZIP Code */}
                <div className="col-md-6">
                    <CustomInputField
                        id="zip-code"
                        label="Postleitzahl"
                        placeholder={disabled ? "-" : "76228"}
                        value={address.postal_code ?? ""}
                        onChange={handleChange('postal_code')}
                        onClear={handleClear('postal_code')}
                        disabled={disabled}
                    />
                </div>
                {/* Country */}
                <div className="col-md-6">
                    <CustomInputField
                        id="country"
                        label="Land"
                        placeholder={disabled ? "-" : "Deutschland"}
                        value={address.country ?? ""}
                        onChange={handleChange('country')}
                        onClear={handleClear('country')}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    );
};

export default AddressForm;
