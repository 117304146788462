// scrollStore.ts
import create from "zustand";

interface ScrollState {
  atBottom: boolean;
  setAtBottom: (value: boolean) => void;
}

const useScrollStore = create<ScrollState>((set) => ({
  atBottom: false,
  setAtBottom: (value) => set({ atBottom: value }),
}));

export default useScrollStore;
