import './file-upload.css';

import React, { CSSProperties, useCallback, useState } from 'react';
import DocumentUploadIcon from '../../assets/icons/document-upload.png';

export interface UploadedFile {
    file: File;
    filename: string;
    filesize: string;
    progress: number;
    uploaded: boolean;
    url?: string;
}

interface FileUploadProps {
    style?: CSSProperties | undefined;
    id: string;
    allowedTypes?: string;
    onFileSelected: (file: UploadedFile) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ style, id, allowedTypes, onFileSelected }) => {
    const [dragging, setDragging] = useState(false);

    const handleDragOver = useCallback((event: React.DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragging(true);
    }, []);

    const handleDragLeave = useCallback((event: React.DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragging(false);
    }, []);

    const handleDrop = useCallback((event: React.DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        setDragging(false);
        Array.from(event.dataTransfer.files).forEach(file => {
            onConvertSelectedFile(file);
        });
    }, []);

    const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            Array.from(event.target.files).forEach(file => {
                onConvertSelectedFile(file);
            });
        }
        event.target.value = '';
    };

    const onConvertSelectedFile = (selectedFile: File) => {
        const fileSizeInKB = selectedFile.size / 1024;
        const fileSizeDisplay = fileSizeInKB < 1024
            ? `${fileSizeInKB.toFixed(2)} KB`
            : `${(fileSizeInKB / 1024).toFixed(2)} MB`;

        const convertedFile: UploadedFile = {
            file: selectedFile,
            filename: selectedFile.name,
            filesize: fileSizeDisplay,
            progress: 100,
            uploaded: true,
        };

        onFileSelected(convertedFile);
    };

    return (
        <label htmlFor={id}
            style={style}
            className={`dashed-upload-container d-flex flex-column align-items-center justify-content-center p-3 cursor-pointer ${dragging ? 'dragging' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}>
            <input
                id={id}
                type="file"
                multiple
                accept={allowedTypes}
                className="file-upload-input"
                onChange={handleFileInput}
                hidden
            />
            <img src={DocumentUploadIcon} alt="Upload" className="upload-icon mb-2" />
            <p className="my-0 text-upload">
                <span className="text-upload-blue me-1">Zum Hochladen klicken</span>
                oder ziehen und ablegen
            </p>
            <small className="max-file-size">(Max. Datei Größe: 25 MB)</small>
        </label>
    );
};

export default FileUpload;
