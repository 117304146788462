// CoursePage.tsx

import { useCallback, useEffect } from "react";
import Switch from "../components/switch/switch";
import useCourseStore from "../stores/courseStore";
import { fetchCourseModuleItems, fetchCourseModules } from "../services/courseService";
import CourseModuleItemCard from "../components/course-module-item-card/course-module-item-card";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "lottie-react";
import LoadingAnim from "../assets/anims/loading-anim.json";

const CoursePage = () => {
    const navigate = useNavigate();

    // Stores
    const {
        modules,
        moduleItems,
        currentPage,
        selectedModuleId,
        isLoading,
        isInitialLoaded,
        moreDataAvailable,
        setModules,
        setModuleItems,
        setCurrentPage,
        setSelectedModuleItem,
        setSelectedModuleId,
        setIsLoading,
        setIsInitialLoaded,
        setMoreDataAvailable
    } = useCourseStore();

    useEffect(() => {
        const loadModules = async () => {
            try {
                setIsLoading(true);
                const fetchedModules = await fetchCourseModules();
                setModules(fetchedModules);
                setIsInitialLoaded(true);
            } catch (error) {
                console.log('Error while fetching course modules:', error);
            } finally {
                setIsLoading(false);
            }
        }
        loadModules();
    }, []);

    const loadUpdates = useCallback(async () => {
        setIsLoading(true);
        try {
            const fetchedItems = await fetchCourseModuleItems(selectedModuleId, currentPage, 20);
            setModuleItems(currentPage === 1 ? fetchedItems : [
                ...moduleItems,
                ...fetchedItems,
            ]);
            setMoreDataAvailable(fetchedItems.length === 20);
        } catch (error) {
            console.error('Error while fetching course module items:', error);
        } finally {
            setIsLoading(false);
        }
    }, [selectedModuleId, currentPage]);

    useEffect(() => {
        loadUpdates();
    }, [loadUpdates]);

    // Infinite scroll fetch function
    const fetchMoreData = () => {
        if (moreDataAvailable && !isLoading) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleFilterClick = (value: string) => {
        setIsInitialLoaded(false);
        setSelectedModuleId(Number(value));
    }

    const onClickCourseItem = (itemId: number) => {
        const selectedModuleItem = moduleItems.find(item => item.item_id === itemId);
        setSelectedModuleItem(selectedModuleItem ?? null);

        if (selectedModuleItem) {
            navigate(`/course/${itemId}`);
        }
    }

    return (
        <>
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-center align-items-center">
                <Switch
                    options={modules.map(modules => {
                        return { label: modules.title, value: modules.module_id.toString() }
                    })}
                    value={selectedModuleId.toString()}
                    onChange={handleFilterClick}
                />
            </div>

            {/* COURSE ITEMS */}
            <div className="d-flex justify-content-between align-items-center mt-5">
                <h4 className="my-0">({moduleItems.length}) Inhalte</h4>
            </div>

            {isLoading && !isInitialLoaded ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                <InfiniteScroll
                    style={{ overflowY: "hidden", overflowX: "hidden" }}
                    dataLength={moduleItems.length}
                    next={fetchMoreData}
                    hasMore={moreDataAvailable}
                    loader={
                        <div className="d-flex justify-content-center mt-4">
                            <div className="spinner-border text-primary" role="status" />
                        </div>
                    }
                    scrollableTarget="content-area"
                >
                    <div className="row mt-1 mb-2 gy-4">
                        {moduleItems.map((module, index) => (
                            <div className="col-12 col-sm-6 col-xl-4" key={index}>
                                <CourseModuleItemCard
                                    title={module.title}
                                    description={module.description}
                                    thumbnail={module.thumbnail_path}
                                    created_at={module.created_at}
                                    onClick={() => onClickCourseItem(module.item_id)}
                                />
                            </div>
                        ))}
                    </div>
                </InfiniteScroll>
            )}
        </>
    );
};

export default CoursePage;
