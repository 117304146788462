import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { IInvestor } from "../services/investorService";
import DefaultAvatar from "../assets/images/default-avatar.webp";
import { SPACES_ENDPOINT } from "../utils/constants";

const STORAGE_NAME = "investor-detail-storage";

interface InvestorDetailState {
  investor: IInvestor | null;
  avatar: string;
  isLoading: boolean;
  isInitialLoaded: boolean;
  setInvestor: (investor: IInvestor) => void;
  setAvatar: (avatar: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsInitalLoaded: (isInitialLoaded: boolean) => void;
  reset: () => void;
}

const useInvestorDetailStore = create<InvestorDetailState>()(
  devtools(
    persist(
      (set) => ({
        investor: null,
        avatar: DefaultAvatar,
        isLoading: true,
        isInitialLoaded: false,
        setInvestor: (investor: IInvestor) => {
          set({
            investor: investor,
            avatar:
              investor.avatar && investor.avatar.length > 0
                ? `${SPACES_ENDPOINT}${investor.avatar}`
                : DefaultAvatar,
          });
        },
        setAvatar: (avatar: string) => set({ avatar }),
        setIsLoading: (isLoading: boolean) => set({ isLoading }),
        setIsInitalLoaded: (isInitialLoaded: boolean) =>
          set({ isInitialLoaded }),
        reset: () => {
          set({ investor: null, avatar: DefaultAvatar, isLoading: true, isInitialLoaded: false });
        },
      }),
      {
        name: STORAGE_NAME,
      }
    )
  )
);

export default useInvestorDetailStore;
