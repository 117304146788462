// UnitDetailPage.tsx

import "./unit-detail-page.css";

import { useEffect, useMemo } from "react";
import _ from 'lodash';
import useAlertStore from "../../stores/alertStore";
import FileCard from "../../components/file-card/file-card";
import CustomButton from "../../components/custom-button/custom-button";
import { useParams } from "react-router-dom";
import { fetchUnit } from "../../services/unitService";
import useUnitDetailStore from "../../stores/unitDetailStore";
import UnitDetails from "./components/unit-details/unit-details";
import GoogleMapCard from "../../components/google-map-card/google-map-card";
import LeaseDetails from "./components/lease-details/lease-details";
import { getActiveLease, getCompletedLeases } from "../../utils/helpers";
import PaymentDetails from "./components/payment-details/payment-details";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading-anim.json";

const UnitDetailPage = () => {
    let { unitId } = useParams();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        unit,
        isInitialLoaded,
        isLoading,
        setUnit,
        setIsInitalLoaded,
        setIsLoading,
    } = useUnitDetailStore();

    useEffect(() => {
        if (unitId) {
            const loadUnit = async () => {
                try {
                    setIsLoading(true);
                    const unit = await fetchUnit(Number(unitId));
                    setUnit(unit);
                    setIsInitalLoaded(true);
                } catch (error) {
                    console.log(`Error while fetching unit data:`, error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsLoading(false);
                }
            }
            loadUnit();
        }
    }, [unitId]);

    const onClickReportProblem = () => {
        alert("Funktion bald Verfügbar!");
    }

    const currentAnnualRent = useMemo(() => {
        if (!unit) return 0;

        const activeLease = getActiveLease(unit.leases);
        if (activeLease) {
            return activeLease.annual_rent_amount;
        }

        const leaseHistory = getCompletedLeases(unit.leases);
        return leaseHistory.length > 0 ? leaseHistory[0].annual_rent_amount : 0;
    }, [unit]);

    return (
        <div className="no-select">
            {isLoading && !isInitialLoaded ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
            </div>
            ) : (
                unit && (
                    <>
                        {/* SECTION TITLE */}
                        <div className="d-flex justify-content-between align-items-center">
                            {unit && (
                                <>
                                    <div className="d-flex justify-content-items align-items-center">
                                        <div>
                                            <h4 className="m-0">Immobilie #{unit.unit_number} - {unit.property.property_name}</h4>
                                            <p className="d-none d-md-block" style={{ margin: 0, fontSize: 14 }}>Solltest du Probleme mit deiner Immobilie haben, zögere nicht, uns zu kontaktieren!</p>
                                        </div>
                                    </div>
                                    <CustomButton
                                        className="d-none d-md-block"
                                        title="Problem melden"
                                        isLoading={false}
                                        disabled={isLoading}
                                        onClick={onClickReportProblem}
                                    />
                                </>
                            )}
                        </div>

                        {/* UNIT OVERVIEW */}
                        <div className="row mt-4 gy-4">
                            <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                                <div className="row gy-4">
                                    <div className="col-12">
                                        <UnitDetails unit={unit} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-7 col-xl-8">
                                <div className="row gy-4">
                                    <div className="col-12">
                                        <PaymentDetails
                                            payments={{
                                                purchasePrice: unit.owner?.purchase_price ?? 0,
                                                annualRent: currentAnnualRent
                                            }}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <LeaseDetails
                                            lease={getActiveLease(unit.leases)}
                                            history={getCompletedLeases(unit.leases)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 className="mt-5">({unit.documents.length}) Verfügbare Dokumente</h4>
                        <p>Du wirst per E-Mail benachrichtigt, sobald neue Dokumente zur Immobilie verfügbar sind.</p>
                        <div className="white-card h-100 mt-3">
                            {unit.documents.length === 0 ? (
                                <p className="my-0">Es wurden noch keine Dokumente für die Immobilie bereitgestellt.</p>
                            ) : (
                                <div className="row gy-3">
                                    {unit.documents.map((document, index) => {
                                        return (
                                            <div className="col-12 col-md-6" key={`unit-document-${document.document_id - index}`}>
                                                <FileCard
                                                    index={`document-file-card-${document.document_id}`}
                                                    documentId={document.document_id}
                                                    fileName={document.file_name}
                                                    filePath={document.file_path}
                                                    progress={100}
                                                    validUntil={document.valid_until}
                                                    uploaded
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>

                        <h4 className="mt-5">Standort</h4>
                        <GoogleMapCard
                            mapContainerStyle={{ width: "100%", height: 400 }}
                            mapsUrl="https://www.google.com/maps/place/The+Haven+Residences+by+Metrical+Real+Estate+Development/@25.063809,55.2049482,17z/data=!4m16!1m9!3m8!1s0x3e5f6ddc297107cf:0xe657c3188c19db21!2sThe+Haven+Residences+by+Metrical+Real+Estate+Development!8m2!3d25.063809!4d55.2049482!9m1!1b1!16s%2Fg%2F11qmmnj_tx!3m5!1s0x3e5f6ddc297107cf:0xe657c3188c19db21!8m2!3d25.063809!4d55.2049482!16s%2Fg%2F11qmmnj_tx?entry=ttu" // Example Google Maps URL
                            radius={17}
                        />

                        <CustomButton
                            className="mt-4 d-md-none w-100"
                            title="Problem melden"
                            isLoading={false}
                            disabled={isLoading}
                            onClick={onClickReportProblem}
                        />
                    </>
                )
            )}
        </div>
    );
};

export default UnitDetailPage;
