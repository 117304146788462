import './custom-button.css';

import React from 'react';
import { Spinner } from 'react-bootstrap';
import { IconType } from 'react-icons';

interface CustomButtonProps {
    className?: string;
    title: string;
    isLoading: boolean;
    disabled?: boolean;
    color?: "blue" | "red";
    icon?: IconType;
    onClick: () => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({ className, title, isLoading, disabled = false, color = "blue", icon: IconComponent, onClick }) => {
    const buttonClassName = color === "blue" ? "btn-primary" : "btn-danger"
    return (
        <button
            className={`btn ${buttonClassName} custom-btn ${className}`}
            onClick={onClick}
            disabled={isLoading || disabled}
        >
            {isLoading ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
                <>
                    {IconComponent && <IconComponent className="me-2 custom-btn-icon" />}
                    {title}
                </>
            )}
        </button>
    );
};

export default CustomButton;
